import { IAdAccountState } from "../Interfaces/IAdAccountState";
import { AdAccount } from "../../models/AdAccount";
import { AdAccountPermissionsRequest } from "../../models/AdAccountPermissionsRequest";

const adAccountsKey : string = "adAccounts";
const selectedAdAccountKey : string = "selectedAdAccount";

const adAccountStore = ({
    namespaced: true,
    state : {
        adAccounts : new Array<AdAccount>(),
        filterText : "",
        selectedAdAccount : new AdAccount(),
    } as IAdAccountState,
    getters: {
        filterText(state: IAdAccountState) {
            return state.filterText;
        },
        selectedAdAccount(state: IAdAccountState) {
            if(state.selectedAdAccount === null || state.selectedAdAccount.id === '' && !(sessionStorage.getItem(selectedAdAccountKey) === null || sessionStorage.getItem(selectedAdAccountKey) === undefined)) {
                state.selectedAdAccount = JSON.parse(<string>sessionStorage.getItem(selectedAdAccountKey));
            }
            return state.selectedAdAccount;
        },
        adAccounts(state: IAdAccountState) {
            if(state.adAccounts.length === 0 && !(sessionStorage.getItem(adAccountsKey) === null || sessionStorage.getItem(adAccountsKey) === undefined)) {
                state.adAccounts = JSON.parse(<string>sessionStorage.getItem(adAccountsKey));
            }
            return state.adAccounts;
        }
    },
    mutations: {
        putAdAccounts(state : IAdAccountState, adAccounts : AdAccount[]) {
            if(adAccounts.length > 0) {
                sessionStorage.setItem(adAccountsKey, JSON.stringify(adAccounts));
                state.adAccounts = JSON.parse(<string>sessionStorage.getItem(adAccountsKey));
            }
        },
        setFilterText(state : IAdAccountState, filterText : string) {
            state.filterText = filterText;
        },
        setSelectedAdAccountById(state: IAdAccountState, selectedAdAccountId : string) {
            let selectedAdAccount = state.adAccounts.filter((x : any) => x.id === selectedAdAccountId)[0];
            sessionStorage.setItem(selectedAdAccountKey, JSON.stringify(selectedAdAccount));
            state.selectedAdAccount = JSON.parse(<string>sessionStorage.getItem(selectedAdAccountKey));
        }
    },
    actions: {
        async setAllAdAccounts({commit, state, rootState} : {commit:any, state:any, rootState: any}) {
            if(sessionStorage.getItem(adAccountsKey) === null || sessionStorage.getItem(adAccountsKey) === undefined)
            {
                if(state.adAccounts === undefined || state.adAccounts === null || state.adAccounts.length === 0) {
                    await rootState.facebookMarketingFactory.GetAllAdAccounts()
                    .then((adAccounts : AdAccount[]) => {
                        commit('putAdAccounts', adAccounts);
                    });
                }
            }
            else{
                state.adAccounts = JSON.parse(<string>sessionStorage.getItem(adAccountsKey));
            }
        },
        async setFilterText({commit}:{commit:any}, filterText : string) {
            commit('setFilterText', filterText);
        },
        async grantAdAccountPermissions({rootState} : {rootState: any}, request : AdAccountPermissionsRequest) {
            return new Promise ((resolve, reject) => {
                rootState.facebookMarketingFactory.GrantAdAccountPermissions(request)
                .then((response : any) => {
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
            })
        },
        async setSelectedAdAccountById({dispatch, commit} : {dispatch:any, commit : any}, adAccountId : string) {
            await dispatch('setAllAdAccounts');
            commit('setSelectedAdAccountById', adAccountId);
        }
    }
});
export default adAccountStore;
