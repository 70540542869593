var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "campaignModal",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "campaignModalLabel",
        "aria-hidden": "true",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "h5",
              { staticClass: "modal-title", attrs: { id: "campaignLabel" } },
              [_vm._v(_vm._s(_vm.modalTitle))]
            ),
            _vm._v(" "),
            _vm._m(0),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _vm.campaignModalStatus === "Delete"
              ? _c("form", [
                  _c("div", { staticClass: "form-group" }, [
                    _c("h6", [
                      _vm._v(
                        "Are you sure you want to delete " +
                          _vm._s(_vm.copiedCampaign.name) +
                          "?"
                      ),
                    ]),
                  ]),
                ])
              : _c("form", { attrs: { id: "campaignForm" } }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "campaignName" } }, [
                      _vm._v("Campaign Name"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.copiedCampaign.name,
                          expression: "copiedCampaign.name",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", id: "campaignName" },
                      domProps: { value: _vm.copiedCampaign.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.copiedCampaign,
                            "name",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.nameValidation
                      ? _c("span", { staticClass: "validation" }, [
                          _vm._v(_vm._s(_vm.nameValidation)),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.campaignModalStatus === "Create" ||
                  _vm.campaignModalStatus === "Duplicate"
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "adAccount" } }, [
                              _vm._v("Ad Account"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "adAccount",
                                readonly: "",
                              },
                              domProps: {
                                value: _vm.copiedCampaign.adAccountId,
                              },
                            }),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "dailyBudget" } }, [
                          _vm._v("Daily Budget ($)"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.dailyBudgetDollars,
                              expression: "dailyBudgetDollars",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "number", id: "dailyBudget" },
                          domProps: { value: _vm.dailyBudgetDollars },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.dailyBudgetDollars = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.dailyBudgetValidation
                          ? _c("span", { staticClass: "validation" }, [
                              _vm._v(_vm._s(_vm.dailyBudgetValidation)),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "campaignStatus" } }, [
                          _vm._v("Status"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.copiedCampaign.status,
                                expression: "copiedCampaign.status",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { id: "campaignStatus" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.copiedCampaign,
                                  "status",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.statuses, function (status) {
                            return _c("option", { key: status.key }, [
                              _vm._v(_vm._s(status)),
                            ])
                          }),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "campaignObjective" } }, [
                          _vm._v("Campaign Objective"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.copiedCampaign.campaignObjective,
                                expression: "copiedCampaign.campaignObjective",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { id: "campaignObjective" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.copiedCampaign,
                                  "campaignObjective",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.campaignObjectives, function (objective) {
                            return _c("option", { key: objective.key }, [
                              _vm._v(_vm._s(objective)),
                            ])
                          }),
                          0
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "specialAdCategory" } }, [
                          _vm._v("Special Ad Category"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.copiedCampaign.specialAdCategory,
                                expression: "copiedCampaign.specialAdCategory",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { id: "specialAdCategory" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.copiedCampaign,
                                  "specialAdCategory",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.specialAdCategories, function (category) {
                            return _c("option", { key: category.key }, [
                              _vm._v(_vm._s(category)),
                            ])
                          }),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.copiedCampaign.budgetOptimization,
                          expression: "copiedCampaign.budgetOptimization",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: { type: "checkbox", id: "budgetOptimization" },
                      domProps: {
                        checked: Array.isArray(
                          _vm.copiedCampaign.budgetOptimization
                        )
                          ? _vm._i(
                              _vm.copiedCampaign.budgetOptimization,
                              null
                            ) > -1
                          : _vm.copiedCampaign.budgetOptimization,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.copiedCampaign.budgetOptimization,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.copiedCampaign,
                                  "budgetOptimization",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.copiedCampaign,
                                  "budgetOptimization",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.copiedCampaign,
                              "budgetOptimization",
                              $$c
                            )
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "budgetOptimization" },
                      },
                      [
                        _vm._v(
                          "\n                            Budget Optimization\n                        "
                        ),
                      ]
                    ),
                  ]),
                ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button", "data-dismiss": "modal" },
                  on: { click: _vm.closeModal },
                },
                [_vm._v("Close")]
              ),
              _vm._v(" "),
              _vm.campaignModalStatus === "Update"
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        type: "button",
                        disabled: _vm.disabledUpdateButton,
                      },
                      on: { click: _vm.updateCampaign },
                    },
                    [_vm._v("Update")]
                  )
                : _vm.campaignModalStatus === "Delete"
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        type: "button",
                        disabled: _vm.disabledDeleteButton,
                      },
                      on: { click: _vm.deleteCampaign },
                    },
                    [_vm._v("Delete")]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        type: "button",
                        disabled: _vm.disabledCreateButton,
                      },
                      on: { click: _vm.createCampaign },
                    },
                    [_vm._v("Create")]
                  ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          id: "campaignModalClose",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }