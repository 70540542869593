import { Company } from "../enums/Company";

export class FormCreationRequest{
    public DomainName!: string;
    public ClientFullName!: string;
    public Niche! : string;
    public Link!: string;
    public FavoriteCity!: string;
    public Location!: string;
    public Source!: string;
    public PageId! : string;
    public Company!: Company;
    public CampaignType!: string;
}

export class CreatorInfoCreationRequest{
    public AdAccountId!: string;
    public Company!: Number;
    public MissingProperties!: CreatorInfoMissingProperty[];
}

export class CreatorInfoMissingProperty{
    public ObjectType!: string;
    public ObjectId!: string;
    public PropertyName!: string;
    public Value!: string;
}