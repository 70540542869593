<template>
    <div class="modal fade" id="campaignModal" tabindex="-1" role="dialog" aria-labelledby="campaignModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="campaignLabel">{{modalTitle}}</h5>
                    <button type="button" id="campaignModalClose" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form v-if="campaignModalStatus === 'Delete'">
                        <div class="form-group">
                            <h6>Are you sure you want to delete {{copiedCampaign.name}}?</h6>
                        </div>
                    </form>
                    <form id="campaignForm" v-else>
                        <div class="form-group">
                            <label for="campaignName">Campaign Name</label>
                            <input type="text" class="form-control" id="campaignName" v-model="copiedCampaign.name">
                            <span class="validation" v-if=nameValidation>{{nameValidation}}</span>
                        </div>
                        <div class="row" v-if="campaignModalStatus === 'Create' || campaignModalStatus === 'Duplicate'">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="adAccount">Ad Account</label>
                                    <input class="form-control" type="text" id="adAccount" :value="copiedCampaign.adAccountId" readonly>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="dailyBudget">Daily Budget ($)</label>
                                    <input type="number" class="form-control" id="dailyBudget" v-model="dailyBudgetDollars">
                                    <span class="validation" v-if=dailyBudgetValidation>{{dailyBudgetValidation}}</span>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="campaignStatus">Status</label>
                                    <select class="form-control" id="campaignStatus" v-model="copiedCampaign.status">
                                        <option v-for="status in statuses" :key="status.key">{{status}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="campaignObjective">Campaign Objective</label>
                                    <select class="form-control" id="campaignObjective" v-model="copiedCampaign.campaignObjective">
                                        <option v-for="objective in campaignObjectives" :key="objective.key">{{objective}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="specialAdCategory">Special Ad Category</label>
                                    <select class="form-control" id="specialAdCategory" v-model="copiedCampaign.specialAdCategory">
                                        <option v-for="category in specialAdCategories" :key=category.key>{{category}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="budgetOptimization" v-model="copiedCampaign.budgetOptimization">
                            <label class="form-check-label" for="budgetOptimization">
                                Budget Optimization
                            </label>
                        </div>
                    </form>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal">Close</button>
                        <button type="button" :disabled="disabledUpdateButton" class="btn btn-primary" v-if="campaignModalStatus === 'Update'" @click="updateCampaign">Update</button>
                        <button type="button" :disabled="disabledDeleteButton" class="btn btn-primary" v-else-if="campaignModalStatus === 'Delete'" @click="deleteCampaign">Delete</button>
                        <button type="button" :disabled="disabledCreateButton" class="btn btn-primary" v-else @click="createCampaign">Create</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Campaign } from '../models/Campaign';
import { EditorStatus } from '../enums/EditorStatus';
import { ValidationResponse } from "../models/ValidationResponse";

@Component
export default class CampaignModal extends Vue {
    @Prop() passedCampaign! : Campaign;
    @Prop() campaignModalStatus!: EditorStatus;
    
    copiedCampaign : Campaign = new Campaign();
    validation : ValidationResponse = new ValidationResponse();
    dailyBudgetDollars : number = 1;
    modalTitle : string = "Create a New Campaign";

    get selectedCampaign() {
        return this.$store.getters['campaigns/selectedCampaign'];
    }
    get selectedAdAccount() {
        return this.$store.getters["adAccounts/selectedAdAccount"];
    }

    @Watch('passedCampaign') 
    onCampaignChange(value: Campaign, oldValue: Campaign) {
        this.copiedCampaign = Campaign.CreateCopy(value);
        this.copiedCampaign.adAccountId = `act_${this.selectedAdAccount.id}`;
        this.dailyBudgetDollars = this.copiedCampaign.dailyBudget / 100;

        this.$store.dispatch('options/getCampaignObjectivesOdax')
            .then((campaignObjectives: string[]) => {
                this.campaignObjectives = campaignObjectives;
            });

        if(this.campaignModalStatus === EditorStatus.Duplicate) {
            this.copiedCampaign.name = `${this.copiedCampaign.name} (copy)`;
        }
    }
    @Watch('dailyBudgetDollars')
    onDailyBudgetDollarsChange(value: number, oldValue: number) {
        this.copiedCampaign.dailyBudget = value * 100;
    }
    @Watch('campaignModalStatus')
    onCampaignModalStatusChange(value: EditorStatus, oldValue: EditorStatus) {
        if(this.campaignModalStatus === EditorStatus.Update) {
            this.modalTitle = "Edit a Campaign";
        }
        else if(this.campaignModalStatus === EditorStatus.Delete) {
            this.modalTitle = "Delete a Campaign";
        }
        else {
            this.modalTitle = "Create a New Campaign";
        }
    }

    get nameValidation() {
        return this.validation.getValidation(Campaign.NameVal);
    }
    get adAccountValidation() {
        return this.validation.getValidation(Campaign.AdAccountVal);
    }
    get dailyBudgetValidation() {
        return this.validation.getValidation(Campaign.DailyBudgetVal);
    }

    //disabled button toggles
    disabledDeleteButton : boolean = false;
    disabledCreateButton : boolean = false;
    disabledUpdateButton : boolean = false;


    //modal dropdowns data
    bidStrategies: string[] = new Array();
    statuses: string[] = new Array();
    campaignObjectives: string[] = new Array();
    specialAdCategories: string[] = new Array();

    async created() {
        this.$store.dispatch('options/getSpecialAdCategories')
            .then((specialAdCategories : string[]) => {
                this.specialAdCategories = specialAdCategories;
            });
        
        this.$store.dispatch('options/getStatuses')
            .then((statuses: string[]) => {
                this.statuses = statuses;
            });
    }

    closeModal() : void {
        this.disabledUpdateButton = false;
        this.disabledDeleteButton = false;
        this.disabledCreateButton = false;
        document.getElementById("campaignModalClose")?.click();
        this.$emit('closed-modal');
    }

    async createCampaign() {
        this.validation = this.copiedCampaign.Validate();
        if(this.validation.IsValid) {
            this.disabledCreateButton = true;
            this.$store.dispatch('campaigns/createCampaign', this.copiedCampaign)
                .catch((error : any) => {
                    this.$toasted.global.error(`An error occurred creating the campaign.<br/> ${error}`);
                })
                .finally(() => {
                    this.disabledCreateButton = false;
                    this.closeModal();
                });
        }
    }
    
    async updateCampaign() {
        this.validation = this.copiedCampaign.Validate();
        if(this.validation.IsValid) {
            this.disabledUpdateButton = true;
            this.$store.dispatch('campaigns/updateCampaign', this.copiedCampaign)
                .catch((error : any) => {
                    this.$toasted.global.error(`An error occurred updating the campaign.<br/> ${error}`);
                })
                .finally(() => {
                    this.disabledUpdateButton = false;
                    this.closeModal();
                });
        }
    }

    async deleteCampaign() {
        this.validation = this.copiedCampaign.Validate();
        if(this.validation.IsValid) {
            this.disabledDeleteButton = true;
            this.$store.dispatch('campaigns/deleteCampaign', this.copiedCampaign)
                .catch((error : any) => {
                    this.$toasted.global.error(`An error occurred deleting the campaign.<br/> ${error}`);
                })
                .finally(() => {
                    this.disabledDeleteButton = false;
                    this.closeModal();
                });
        }
    }
}
</script>
