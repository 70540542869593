import { Company } from "../../enums/Company";
import { LeadFormModel } from "../../models/LeadForm";
import { ILeadFormState } from '../Interfaces/ILeadFormState';

const leadFormStore = ({
    namespaced: true,
    state: {
        LeadForms: new Array<LeadFormModel>()
    } as ILeadFormState,
    actions: {
        async getFacebookForms ({rootState}: {rootState: any}, fbPageId: string) {
            return new Promise ((resolve, reject) => {
                rootState.facebookMarketingFactory.GetFacebookForms(fbPageId)
                .then((response : any) => {
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
            })
        },
        async getCompanyFacebookForms ({rootState}: {rootState: any}, formRequestObj: { fbPageId: string, company : Company }) {
            return new Promise ((resolve, reject) => {
                rootState.facebookMarketingFactory.GetCompanyFacebookForms(formRequestObj.fbPageId, formRequestObj.company)
                .then((response : any) => {
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
            })
        },
        async getFormTemplates ({rootState}: {rootState: any}) {
            return new Promise ((resolve, reject) => {
                rootState.facebookMarketingFactory.GetFormTemplates()
                .then((response : any) => {
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
            })
        },
        async getLeadForm ({rootState}: {rootState: any}, leadFormObj: {fbPageId: string, formId: string}) {
            return new Promise ((resolve, reject) => {
                rootState.facebookMarketingFactory.GetLeadForm(leadFormObj.fbPageId, leadFormObj.formId)
                .then((response : any) => {
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
            })
        },
        async createLeadForm ({rootState}: {rootState: any}, leadForm: LeadFormModel) {
            return new Promise ((resolve, reject) => {
                rootState.facebookMarketingFactory.CreateLeadForm(leadForm)
                .then((response : any) => {
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
            })
        },
    },
})

export default leadFormStore