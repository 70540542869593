<template>
    <div class="component-container">
        <div class="display-5 mb-3 mt-3" >Creator Info Loader</div>
        <div class="row">
            <div class="col-sm-10">
                <p class="m-1 mb-3">Load an ad account into the creator that has already been created or imported into the system.</p>
                <div class="input-group mb-3 mt-1">
                    <input v-model="filterInput" type="text" class="form-control" placeholder="Search by Ad Account Name or ID">
                    <span class="input-group-text"><font-awesome-icon :icon="['fas', 'magnifying-glass']" /></span>
                </div>
                <div class="list-group">
                    <a href="#"  @click="loadCreatorInfo(creatorInfo.id)" class="list-group-item list-group-item-action d-flex justify-content-between align-items-start" :key="creatorInfo.id" v-for="creatorInfo in filteredExistingCreatorInfos">
                        <div class="ms-1 me-auto">
                            <h6>{{creatorInfo.name}}</h6>
                            <small class="text-muted">{{creatorInfo.id}}</small>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <h4 class="display-6 m-5"> - Or - </h4>
        <AdAccountLoader />
    </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import AdAccountLoader from './component/AdAccountLoader.vue';

@Component({
    name: 'InfoLoader',
    components: {
        AdAccountLoader
    }
})
export default class InfoLoader extends Vue {

    filterInput: string = '';
    existingCreatorInfos: any = [];

    get filteredExistingCreatorInfos() {
        const loweredAndTrimmedInput = this.filterInput.toLocaleLowerCase().trim();
        if(this.filterInput === '')
            return [];
        return this.existingCreatorInfos.filter((x : any) => x.name.toLowerCase().includes(loweredAndTrimmedInput) || x.id.includes(loweredAndTrimmedInput));
    }

    async created() {
        this.existingCreatorInfos = await this.$store.dispatch('creator/getExistingCreatorInfos', false);
    }

    async loadCreatorInfo(adAccountId: string) {
        this.$store.dispatch('creator/loadCreatorInfo', adAccountId)
        .then(() => {
            this.$router.push(`/facebook/creator/campaign`);
        })
        .catch((error: any) => {
            this.$toasted.global.error(`An error occured while trying to load the creator info.`);
        });
    }
};
</script>