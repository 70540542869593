var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "component-container" }, [
    _c("div", { staticClass: "row header-no-navigation component-header" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "row row-padding-margins" }, [
          _c("div", { staticClass: "input-group mb-3" }, [
            _c(
              "label",
              {
                staticClass: "col-form-label text-input-label",
                attrs: { for: "filterText" },
              },
              [_vm._v("Search Ad Accounts:")]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "input-group mb-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filterText,
                    expression: "filterText",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "search",
                  placeholder: "Search",
                  "aria-label": "Search",
                  "aria-describedby": "button-addon2",
                },
                domProps: { value: _vm.filterText },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.filterText = $event.target.value
                  },
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        !_vm.adAccountsRequested
          ? _c("div", { staticClass: "text-center" }, [_vm._m(1)])
          : _c(
              "table",
              {
                staticClass:
                  "ad-accounts-table table-scroll table table-striped",
              },
              [
                _c("thead", [
                  _c("tr", [
                    _c(
                      "th",
                      {
                        staticClass: "pointer name",
                        attrs: { scope: "col" },
                        on: {
                          click: function ($event) {
                            return _vm.sortByKey("adAccounts")
                          },
                        },
                      },
                      [_vm._v("Name")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  { staticClass: "ad-accounts-tbody body-half-screen" },
                  [
                    _vm._l(_vm.filteredAdAccounts, function (adAccount) {
                      return _c("tr", { key: adAccount.id }, [
                        _c(
                          "th",
                          {
                            staticClass: "href tbody-adAccount",
                            attrs: { scope: "row" },
                            on: {
                              click: function ($event) {
                                return _vm.goToAdAccount(adAccount.id)
                              },
                            },
                          },
                          [_vm._v(_vm._s(adAccount.name))]
                        ),
                      ])
                    }),
                    _vm._v(" "),
                    _c(
                      "infinite-loading",
                      {
                        attrs: { identifier: _vm.infiniteId },
                        on: { infinite: _vm.infiniteHandler },
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "no-more" }, slot: "no-more" },
                          [_vm._v(_vm._s(this.infiniteLoadResult))]
                        ),
                      ]
                    ),
                  ],
                  2
                ),
              ]
            ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-10 inline-display-box" }, [
        _c("h3", { staticClass: "object-title" }, [_vm._v("Ad Accounts")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "spinner-border m-5", attrs: { role: "status" } },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }