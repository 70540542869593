import { Ad } from "../../models/Ad";
import { EditorStatus } from '../../enums/EditorStatus';
import { IAdState } from '../Interfaces/IAdState';
import { Template } from "../../models/Template";
import { Company } from "../../enums/Company";

const adStore = ({
    namespaced: true,
    state: {
        EditingAd: false,
        SelectedAd: new Ad(),
        Ads: new Array<Ad>(),
        Templates: new Array<Template>(),
        DynamicTemplates : new Array<Template>()
    } as IAdState,
    getters: {
        selectedAd(state: IAdState) {
            return state.SelectedAd;
        },
        getAds(state: IAdState) {
            return state.Ads;
        }
    },
    mutations: {
        putSelectedAd(state : IAdState, ad : Ad) {
            state.SelectedAd = ad;
        },
        setNewLeadFormId(state : IAdState, formId : string) {
            state.SelectedAd.formId = formId;
        },
        duplicateAd(state : IAdState) {
            state.SelectedAd.id = "";
        },
        putAds(state : IAdState, ads : Ad[]) {
            if(ads.length > 0) {
                state.Ads = ads;
            }
        },
        putTemplates(state : IAdState, templates : Template[]) {
            state.Templates = templates;
        },
        putDynamicTemplates(state : IAdState, templates : Template[]) {
            state.DynamicTemplates = templates;
        },
        processUpdatedAd(state : IAdState, adObj : {ad:Ad , status:string}) {
            let adIndex = state.Ads.findIndex((a) => { return a.id === adObj.ad?.id; });

            if (adObj.status.toLowerCase() === 'delete') {
                state.Ads.splice(adIndex, 1);
            } else if (adObj.status.toLowerCase() === 'update') {
                state.Ads.splice(adIndex, 1, adObj.ad);
            } else if (adObj.status.toLowerCase() === 'create') {
                state.Ads.push(adObj.ad);
            }
        },
    },
    actions: {
        async getSelectedAdById({commit,state,rootState}:{commit:any,state:any,rootState:any}, adObj : {adId:string , adEditorStatus:EditorStatus, adsetId:string}) {
            if(adObj.adId === "" || adObj.adId === undefined)//if no adId to fetch return -- should mean this is create
                return;
            if (state.SelectedAd.id === adObj.adId || (state.SelectedAd.adsetId === adObj.adsetId && adObj.adEditorStatus === EditorStatus.Duplicate)) {//if we have the adId or adSetId the item is in the store
                if (state.SelectedAd.id !== "" && adObj.adEditorStatus === EditorStatus.Duplicate)//if its a dupe removed the adId so when we post this ad we make a new adId
                    commit('duplicateAd');
                return;
            }

            return await rootState.facebookMarketingFactory.GetAdByAdId(adObj.adId)
                .then((selectedAd : Ad) => {
                    if(adObj.adEditorStatus === EditorStatus.Update)
                        selectedAd.id = adObj.adId;
        
                    selectedAd.adsetId = adObj.adsetId;
                    
                    commit('putSelectedAd', selectedAd);
                })
                .catch((error: any) => {
                    throw new Error(error);
                });
        },
        async getAdsByAdsetId({commit, state, rootState}: {commit: any, state: any, rootState: any}, adsetId: string) {
            let selectedAdsByAdsetId : Ad[] = state.Ads.filter((ad : Ad) => ad.adsetId === adsetId);

            if(selectedAdsByAdsetId.length > 0)
                return selectedAdsByAdsetId;
            else{
                let adsByAdsetId : Ad[] = await rootState.facebookMarketingFactory.GetAdsByAdsetId(adsetId);
                commit('putAds', adsByAdsetId);
                return adsByAdsetId;
            }
        },
        putSelectedAd(context : any, ad : Ad) {
            context.commit('putSelectedAd', ad);
        },
        putNewLeadFormId (context : any, formId : string) {
            context.commit('setNewLeadFormId', formId);
        },
        async updateAdStatus ({rootState}: {rootState: any}, adObj : {adId: string, type: string, status: string}) {
            await rootState.facebookMarketingFactory.UpdateStatus(adObj.adId, adObj.type, adObj.status);
        },
        async getAllTemplates ({commit, state, rootState}: {commit: any, state: any, rootState: any}) {
            if(state.Templates === undefined || state.Templates.length === 0) {
                await rootState.facebookMarketingFactory.GetAdTemplates()
                .then((templates : Template[]) => {
                    commit('putTemplates', templates);
                });
            }
            return state.Templates;
        },
        async getDynamicTemplates ({commit, state, rootState}: {commit: any, state: any, rootState: any}) {
            if(state.DynamicTemplates === undefined || state.DynamicTemplates.length === 0) {
                await rootState.facebookMarketingFactory.GetDynamicAdTemplates()
                .then((templates : Template[]) => {
                    commit('putDynamicTemplates', templates);
                });
            }
            return state.DynamicTemplates;
        },
        createImageHash ({rootState}: {rootState: any}, imageObj: {byteString: string, adAccountId: string, company: Company}) {
            return new Promise ((resolve, reject) => {
                rootState.facebookMarketingFactory.CreateImageHash(imageObj.byteString, imageObj.adAccountId, imageObj.company)
                .then((response : any) => {
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
            })
        },
        async createAdPreview ({rootState}: {rootState: any}, ad: Ad) {
            return new Promise ((resolve, reject) => {
                rootState.facebookMarketingFactory.CreateAdPreview(ad)
                .then((response : any) => {
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
            })
        },
        async getAdTemplateById ({rootState}: {rootState: any}, adTextId : string) {
            return new Promise ((resolve, reject) => {
                rootState.facebookMarketingFactory.GetAdTemplateById(adTextId)
                .then((response : any) => {
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
            })
        },
        async getDynamicAdTemplateById ({rootState}: {rootState: any}, adTextId : string) {
            return new Promise ((resolve, reject) => {
                rootState.facebookMarketingFactory.GetDynamicAdTemplateById(adTextId)
                .then((response : any) => {
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
            })
        },
        async getCTAs ({rootState}: {rootState: any}) {
            return new Promise ((resolve, reject) => {
                rootState.facebookMarketingFactory.GetCTAs()
                .then((response : any) => {
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
            })
        },
        async createAd ({commit, rootState}: {commit: any, rootState: any}, ad: Ad) {
            return new Promise ((resolve, reject) => {
                rootState.facebookMarketingFactory.CreateAd(ad)
                .then((id : string) => {
                    ad.id = id;
                    commit('processUpdatedAd', {ad: ad, status: "create"});
                    resolve(id);
                }, (error : any) => {
                    reject(error);
                })
            })
        },
        async deleteAd ({commit, rootState}: {commit: any, rootState: any}, ad: Ad) {
            return new Promise ((resolve, reject) => {
                rootState.facebookMarketingFactory.DeleteAd(ad.id)
                .then((response : any) => {
                    commit('processUpdatedAd', {ad: ad, status: "delete"});
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
            })
        },
        async updateAd ({commit, rootState}: {commit: any, rootState: any}, ad: Ad) {
            await rootState.facebookMarketingFactory.UpdateAd(ad)
                .then(() => {
                    commit('processUpdatedAd', {ad: ad, status: "update"});
                });
        },
    },
})

export default adStore