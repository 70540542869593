var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "component-container" },
    [
      _c("div", { staticClass: "row component-header" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-8" }, [
              _c(
                "i",
                {
                  staticClass: "fa fa-chevron-left href",
                  on: { click: _vm.backToAdAccounts },
                },
                [_vm._v(" Back to ad accounts")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-10 inline-display-box" }, [
              _c("h3", { staticClass: "object-title" }, [
                _vm._v(_vm._s(_vm.selectedAdAccount.name)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-2 text-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: {
                    "data-toggle": "modal",
                    "data-target": "#campaignModal",
                  },
                  on: { click: _vm.createCampaignScreen },
                },
                [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" Campaign")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row row-padding-margins" }, [
            _c("div", { staticClass: "col-sm-9 input-group mb-3" }, [
              _c(
                "label",
                {
                  staticClass: "col-form-label text-input-label",
                  attrs: { for: "filterText" },
                },
                [_vm._v("Search Campaigns:")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "input-group mb-3" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filterText,
                      expression: "filterText",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "search",
                    placeholder: "Search",
                    "aria-label": "Search",
                    "aria-describedby": "button-addon2",
                  },
                  domProps: { value: _vm.filterText },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.filterText = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-3" }, [
              _c(
                "label",
                {
                  staticClass: "col-form-label text-input-label",
                  attrs: { for: "status" },
                },
                [_vm._v("Status:")]
              ),
              _vm._v(" "),
              _c(
                "select",
                {
                  staticClass: "form-control",
                  attrs: { disabled: !_vm.campaignsRequested },
                  domProps: { value: _vm.filterStatus },
                  on: {
                    change: function ($event) {
                      return _vm.updateFilterState($event)
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "None" } }, [_vm._v("All")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Active" } }, [
                    _vm._v("Active"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Paused" } }, [
                    _vm._v("Paused"),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          !_vm.campaignsRequested
            ? _c("div", { staticClass: "text-center" }, [_vm._m(0)])
            : _c(
                "table",
                {
                  staticClass:
                    "campaigns-table table-scroll table table-striped",
                },
                [
                  _c("thead", [
                    _c("tr", [
                      _c(
                        "th",
                        {
                          staticClass: "pointer name",
                          attrs: { scope: "col" },
                          on: {
                            click: function ($event) {
                              return _vm.sortByKey("name")
                            },
                          },
                        },
                        [_vm._v("Name")]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass: "pointer status",
                          attrs: { scope: "col" },
                          on: {
                            click: function ($event) {
                              return _vm.sortByKey("status")
                            },
                          },
                        },
                        [_vm._v("Status")]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass: "pointer create-date",
                          attrs: { scope: "col" },
                          on: {
                            click: function ($event) {
                              return _vm.sortByKey("createDate")
                            },
                          },
                        },
                        [_vm._v("Created")]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        { staticClass: "actions", attrs: { scope: "col" } },
                        [_vm._v("Actions")]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    { staticClass: "campaigns-tbody body-half-screen" },
                    [
                      _vm._l(_vm.filteredCampaigns, function (campaign) {
                        return _c("tr", { key: campaign.id }, [
                          _c(
                            "th",
                            {
                              staticClass: "href tbody-name",
                              attrs: { scope: "row" },
                              on: {
                                click: function ($event) {
                                  return _vm.goToCampaign(campaign.id)
                                },
                              },
                            },
                            [_vm._v(_vm._s(campaign.name))]
                          ),
                          _vm._v(" "),
                          _c("td", { staticClass: "tbody-status" }, [
                            _c(
                              "div",
                              {
                                class: [
                                  campaign.status === "ACTIVE"
                                    ? "active-chip"
                                    : "paused-chip",
                                ],
                              },
                              [_vm._v(_vm._s(campaign.status))]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "tbody-create-date" }, [
                            _vm._v(
                              _vm._s(campaign.createDate.toLocaleString())
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "tbody-actions" }, [
                            _vm._m(1, true),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "dropdown-menu dropdown-menu-right",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: {
                                      "data-toggle": "modal",
                                      "data-target": "#campaignModal",
                                      type: "button",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editCampaignScreen(
                                          campaign.id
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Edit")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: {
                                      "data-toggle": "modal",
                                      "data-target": "#campaignModal",
                                      type: "button",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.duplicateCampaignScreen(
                                          campaign.id
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Duplicate")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: {
                                      "data-toggle": "modal",
                                      "data-target": "#campaignModal",
                                      type: "button",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteCampaignScreen(
                                          campaign.id
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Delete")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "dropdown-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeStatus(campaign.id)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        campaign.status === "ACTIVE"
                                          ? "Pause"
                                          : "Set Active"
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ])
                      }),
                      _vm._v(" "),
                      _c(
                        "infinite-loading",
                        {
                          attrs: { identifier: _vm.infiniteId },
                          on: { infinite: _vm.infiniteHandler },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "no-more" }, slot: "no-more" },
                            [_vm._v(_vm._s(this.infiniteLoadResult))]
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ]
              ),
        ]),
      ]),
      _vm._v(" "),
      _c("CampaignModal", {
        attrs: {
          passedCampaign: _vm.selectedCampaign,
          campaignModalStatus: _vm.campaignModalStatus,
        },
        on: { "closed-modal": _vm.modalClosed },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "spinner-border m-5", attrs: { role: "status" } },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "a",
      {
        staticClass: "wizard-more",
        attrs: {
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
        },
      },
      [_c("i", { staticClass: "fa fa-ellipsis-h" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }