import { IOptionState } from "../Interfaces/IOptionState";

const optionStore = ({
    namespaced: true,
    state: {
        CampaignObjectives : new Array<string>(),
        CampaignObjectivesOdax: new Array<string>(),
        SpecialAdCategories : new Array<string>(),
        Statuses : new Array<string>()
    } as IOptionState,
    getters: {},
    mutations: {
        async putCampaignObjectives(state: IOptionState, campaignObjectives: string[]) {
            if(campaignObjectives.length > 0) {
                state.CampaignObjectives = campaignObjectives;
            }
        },
        async putCampaignObjectivesOdax(state: IOptionState, campaignObjectivesOdax: string[]) {
            if(campaignObjectivesOdax.length > 0) {
                state.CampaignObjectivesOdax = campaignObjectivesOdax;
            }
        },
        async putSpecialAdCategories(state: IOptionState, specialAdCategories: string[]) {
            if(specialAdCategories.length > 0) {
                state.SpecialAdCategories = specialAdCategories;
            }
        },
        async putStatuses(state: IOptionState, statuses: string[]) {
            if(statuses.length > 0) {
                state.Statuses = statuses;
            }
        }
    },
    actions: {
        async getCampaignObjectives({commit, state, rootState} : {commit: any, state: any, rootState: any}) {
            if(state.CampaignObjectives === undefined || state.CampaignObjectives.length === 0) {
                await rootState.facebookMarketingFactory.GetOption("campaignobjective")
                    .then((campaignObjectives: string[]) => {
                        commit('putCampaignObjectives', campaignObjectives);
                    });
            }
            return state.CampaignObjectives;
        },
        async getCampaignObjectivesOdax({commit, state, rootState} : {commit: any, state: any, rootState: any}) {
            if(state.CampaignObjectivesOdax === undefined || state.CampaignObjectivesOdax.length === 0) {
                await rootState.facebookMarketingFactory.GetOption("campaignobjectiveodax")
                    .then((campaignObjectivesOdax: string[]) => {
                        commit('putCampaignObjectivesOdax', campaignObjectivesOdax);
                    });
            }
            return state.CampaignObjectivesOdax;
        },
        async getSpecialAdCategories({commit, state, rootState} : {commit: any, state: any, rootState: any}) {
            if(state.SpecialAdCategories === undefined || state.SpecialAdCategories.length === 0) {
                await rootState.facebookMarketingFactory.GetOption("specialadcategory")
                    .then((specialAdCategories: string[]) => {
                        commit('putSpecialAdCategories', specialAdCategories);
                    });
            }
            return state.SpecialAdCategories;
        },
        async getStatuses({commit, state, rootState} : {commit: any, state: any, rootState: any}) {
            if(state.Statuses === undefined || state.Statuses.length === 0) {
                await rootState.facebookMarketingFactory.GetOption("status")
                    .then((statuses: string[]) => {
                        commit('putStatuses', statuses);
                    });
            }
            return state.Statuses;
        }
    }
});
export default optionStore;