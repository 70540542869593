<template>
    <div id="location-selector">
        <label for="locations">Targeting Location Search</label>
        <div class="row g-3">
                <div class="col-md-6">
                    <div class="mb-3">
                        <input type="text" disabled readonly class="form-control" id="locationTypes" value="People living in or recently in this location">
                    </div>
                </div>
                <div class="col-md-6">
                    <div id="search-area">
                        <input type="search" id="search-input" class="form-control" v-model.trim="searchInput" @focus="checkShowResults" placeholder="Search Locations" />
                        <div class="container search-results-container" v-if="showResults">
                            <div class="row row-hover" v-for="location in locationResults" :key=location.key @click="clickSearchResult(location)">
                                <div class="col border p-2">
                                    {{location.name}}, {{location.region}}  {{location.country_code}} <span style="float:right;" class="badge bg-light text-dark">{{location.type}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <label for="selected-locations" class="from-control">Selected Locations:</label>
            <div style="display:flex;" class="border p-2" v-for="region in locationSelectorResults.regions" :key=region.key>
                <div class="col-md-6">
                    {{region.name}} - {{region.country}} <span class="badge badge-light">Region</span>
                </div>
                <div class="col-md-5">
                </div>
                <div class="col-md-1"> 
                    <font-awesome-icon :icon="['far', 'circle-xmark']" style="float:right; cursor: pointer;" @click="clickRemoveRegion(region)"></font-awesome-icon>
                </div>
            </div>
            <div style="display:flex;" class="border p-2" v-for="county in locationSelectorResults.counties" :key=county.key>
                <div class="col-md-6">
                    {{county.name}} - {{county.region}} - {{county.country_code}} <span class="badge badge-light">County</span>
                </div>
                <div class="col-md-5">
                </div>
                <div class="col-md-1 d-flex align-items-center justify-content-end"> 
                    <font-awesome-icon :icon="['far', 'circle-xmark']"  style="float:right; cursor: pointer;" @click="clickRemoveCounty(county)" ></font-awesome-icon>
                </div>
            </div>
            <div style="display:flex;" class="border p-2" v-for="city in locationSelectorResults.cities" :key=city.key>
                <div class="col-md-6">
                    {{city.name}}, {{city.region}} - {{city.country}}  <span class="badge badge-light">City</span>
                </div>
                <div class="col-md-5">
                    <span>Radius : <input class="form-item" type="number" min="15" max="50" v-model="city.radius" value="20" /> miles</span>
                </div>
                <div class="col-md-1 d-flex align-items-center justify-content-end" >
                    <font-awesome-icon :icon="['far', 'circle-xmark']"  style="float:right; cursor: pointer;" @click="clickRemoveCity(city)" ></font-awesome-icon>
                </div>
            </div>
            <span class="validation" v-if="locationValidation">{{locationValidation}}</span>
    </div>
</template>

<style>
    .row-hover:hover {
        background-color: rgba(0,0,0,.05);
    }
    .search-results-container {
        position: absolute;
        z-index: 1000;
        width: 48%;
        background-color: white;
        border: 1px solid #ccc;
    }
</style>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { City } from '../models/City';
import { Region} from '../models/Region';
import { County } from '../models/County';
import { SearchLocationResult, LocationSelectorResults } from '../models/Locations';


@Component
export default class LocationSelector extends Vue {

    searchInput: string = "";
    locationResults : SearchLocationResult[] = new Array();
    showResults: boolean = false;
    selectedLocationTypes : string[] = new Array();

    locationRequestTimeout! : NodeJS.Timeout;

    @Prop() locationSelectorResults! : LocationSelectorResults;
    @Prop() locationValidation : string = "";

    @Watch('searchInput')
    watchSearchInput() {
        this.showResults = this.searchInput.trim() !== "";
    }

    async created() : Promise<void> {
        this.getLocationTypes();
        document.addEventListener("click", this.clickSearchAreaEvent);
    }

    destroyed() : void {
        document.removeEventListener("click", this.clickSearchAreaEvent);
    }

    clickSearchAreaEvent(event: Event) : void {
        let searchArea : Node = <Node>document.getElementById("search-area");
        this.showResults = this.searchInput.trim() !== "" && searchArea.contains(<Node>event.target);
    }

    checkShowResults() {
        this.showResults = this.searchInput.trim() !== "";
    }

    @Watch('searchInput')
    onSearchInputChange(value: string, oldValue: string) {
        if(this.locationRequestTimeout) {
            clearTimeout(this.locationRequestTimeout);
        }
        this.getLocationSearchResults(value);
    }
       
    getLocationTypes() {

    }

    getLocationSearchResults(search : string) {
        if(search.length > 2) {
            let _this = this;
            this.locationRequestTimeout = setTimeout(function() { _this.locationRequest(search); }, 250);
        }
    }

    locationRequest(search : string) {
        this.$store.dispatch('location/getLocationsBySearch', search)
            .then((response : any) => {
                this.locationResults = response;
            })
            .catch((error : any) => {
                this.$toasted.global.error(`An error occured requesting locations.<br/> ${error}`);
            });
    }

    //user events
    clickSearchResult(location : SearchLocationResult) {

        if(location.type === "city") {
            let city = new City();
            city.key = location.key;
            city.name = location.name;
            city.regionId = location.region_id;
            city.country = location.country_code;
            city.region = location.region;
            if(this.locationSelectorResults.cities.indexOf(city) === -1)
                this.locationSelectorResults.cities.push(city);
        }
        else if (location.type === "medium_geo_area"){
            let county = new County();
            county.key = location.key;
            county.name = location.name;
            county.region = location.region;
            county.country_code = location.country_code;
            if(this.locationSelectorResults.counties.indexOf(county) === -1)
                this.locationSelectorResults.counties.push(county);
        }
        else {
            let region = new Region();
            region.key = location.key;
            region.name = location.name;
            region.country = location.country_code;
            region.type = location.type;
            if(this.locationSelectorResults.regions.indexOf(region) === -1)
                this.locationSelectorResults.regions.push(region);
        }
        this.showResults = false;
    }
    clickRemoveRegion(region : Region) {
        let ind = this.locationSelectorResults.regions.indexOf(region);
        this.locationSelectorResults.regions.splice(ind, 1);
    }
    clickRemoveCity(city : City) {
        let ind = this.locationSelectorResults.cities.indexOf(city);
        this.locationSelectorResults.cities.splice(ind, 1);
    }
    clickRemoveCounty(county : County) {
        let ind = this.locationSelectorResults.counties.indexOf(county);
        this.locationSelectorResults.counties.splice(ind, 1);
    }
}

</script>

