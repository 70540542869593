var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c("Sidebar"),
      _vm._v(" "),
      _c("div", { staticClass: "component-container-sidebar" }, [
        _c("h6", { staticClass: "ms-3 mt-3" }, [
          _vm._v(
            "Account ID: " + _vm._s(_vm.creatorInfo.AdAccountId.substring(4))
          ),
        ]),
        _vm._v(" "),
        _c("h6", { staticClass: "ms-3" }, [
          _vm._v("Campaign ID: " + _vm._s(_vm.selectedCampaign.CampaignId)),
        ]),
        _vm._v(" "),
        _c("h6", { staticClass: "ms-3" }, [
          _vm._v("Ad Set ID: " + _vm._s(_vm.selectedAdSet.AdsetId)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card mb-3 mt-3" }, [
          _c("div", { staticClass: "card-header" }, [
            _vm._v("\n                Ad Creator\n            "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            !_vm.displaySuccess
              ? _c(
                  "form",
                  {
                    staticClass: "needs-validation",
                    attrs: { novalidate: "" },
                  },
                  [
                    _c("div", { staticClass: "container" }, [
                      _c("div", { staticClass: "row mt-3" }, [
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c("div", { staticClass: "form-group mb-2" }, [
                            _c("label", { attrs: { for: "adName" } }, [
                              _vm._v("Ad Name"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.name,
                                  expression: "name",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", id: "adName" },
                              domProps: { value: _vm.name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.name = $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.nameValidation !== ""
                              ? _c("p", {
                                  staticClass: "validation",
                                  domProps: {
                                    textContent: _vm._s(_vm.nameValidation),
                                  },
                                })
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group mb-2" }, [
                            _c("label", { attrs: { for: "adTemplates" } }, [
                              _vm._v("Ad Templates"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedTemplateId,
                                    expression: "selectedTemplateId",
                                  },
                                ],
                                staticClass: "form-select",
                                attrs: {
                                  disabled: _vm.adTemplates.length === 0,
                                  id: "adTemplates",
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.selectedTemplateId = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    _vm.updateAdTextFields,
                                  ],
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("Please Select an Ad Template"),
                                ]),
                                _vm._v(" "),
                                _vm._l(_vm.adTemplates, function (adTemplate) {
                                  return _c(
                                    "option",
                                    {
                                      key: adTemplate.id,
                                      domProps: { value: adTemplate.id },
                                    },
                                    [_vm._v(_vm._s(adTemplate.name))]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.placeholders.length > 0
                            ? _c(
                                "div",
                                _vm._l(
                                  _vm.placeholders,
                                  function (placeholder) {
                                    return _c(
                                      "div",
                                      {
                                        key: placeholder.name,
                                        staticClass: "form-group mb-2",
                                      },
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: placeholder.name } },
                                          [_vm._v(_vm._s(placeholder.name))]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: placeholder.value,
                                              expression: "placeholder.value",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            name: placeholder.name,
                                          },
                                          domProps: {
                                            value: placeholder.value,
                                          },
                                          on: {
                                            blur: _vm.blurPlaceholderInput,
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                placeholder,
                                                "value",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group mb-2" }, [
                            _c("label", { attrs: { for: "adHeadline" } }, [
                              _vm._v("Headline"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.headline,
                                  expression: "headline",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", id: "adHeadline" },
                              domProps: { value: _vm.headline },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.headline = $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.headlineValidation !== ""
                              ? _c("p", { staticClass: "validation" }, [
                                  _vm._v(_vm._s(_vm.headlineValidation)),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group mb-2" }, [
                            _c("label", { attrs: { for: "adDescription" } }, [
                              _vm._v("Description"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.description,
                                  expression: "description",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", id: "adDescription" },
                              domProps: { value: _vm.description },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.description = $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.descriptionValidation !== ""
                              ? _c("p", { staticClass: "validation" }, [
                                  _vm._v(_vm._s(_vm.descriptionValidation)),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group mb-4" }, [
                            _c("label", { attrs: { for: "adPrimaryText" } }, [
                              _vm._v("Primary Text"),
                            ]),
                            _vm._v(" "),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.primaryText,
                                  expression: "primaryText",
                                },
                              ],
                              staticClass: "form-control",
                              staticStyle: { height: "150px" },
                              attrs: { id: "adPrimaryText" },
                              domProps: { value: _vm.primaryText },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.primaryText = $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.primaryTextValidation !== ""
                              ? _c("p", { staticClass: "validation" }, [
                                  _vm._v(_vm._s(_vm.primaryTextValidation)),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _vm.isSeller
                            ? _c("div", { staticClass: "form-group mb-2" }, [
                                _c("label", { attrs: { for: "linkUrl" } }, [
                                  _vm._v("Destination Url"),
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.linkUrl,
                                      expression: "linkUrl",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: { type: "text", id: "linkUrl" },
                                  domProps: { value: _vm.linkUrl },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.linkUrl = $event.target.value
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _vm.linkUrlValidation !== ""
                                  ? _c("p", { staticClass: "validation" }, [
                                      _vm._v(_vm._s(_vm.linkUrlValidation)),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isSeller
                            ? _c("div", { staticClass: "form-group mb-4" }, [
                                _c("label", { attrs: { for: "displayUrl" } }, [
                                  _vm._v("Display Url"),
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.displayUrl,
                                      expression: "displayUrl",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    placeholder: "cincpro.com",
                                    id: "displayUrl",
                                  },
                                  domProps: { value: _vm.displayUrl },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.displayUrl = $event.target.value
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("small", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    '* Do not include "?src=FBSELL" in the url. This will be automatically added.'
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.displayUrlValidation !== ""
                                  ? _c("p", { staticClass: "validation" }, [
                                      _vm._v(_vm._s(_vm.displayUrlValidation)),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isDynamicAd
                            ? _c("div", { staticClass: "ad-image-upload" }, [
                                _c("div", { staticClass: "form-group mb-2" }, [
                                  _c("label", { attrs: { for: "adImage" } }, [
                                    _vm._v("Upload Image"),
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    ref: "imageFile",
                                    staticClass: "form-control-file",
                                    attrs: {
                                      type: "file",
                                      accept: "image/*",
                                      id: "adImage",
                                    },
                                    on: { change: _vm.handleImageUpload },
                                  }),
                                ]),
                                _vm._v(" "),
                                _vm._m(0),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group mb-2" }, [
                                  _c("label", { attrs: { for: "ImageUrl" } }, [
                                    _vm._v("Image Link"),
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.imageUrl,
                                        expression: "imageUrl",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "ImageUrl",
                                      placeholder: "http://",
                                    },
                                    domProps: { value: _vm.imageUrl },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.imageUrl = $event.target.value
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.imageValidation !== ""
                                    ? _c(
                                        "span",
                                        { staticClass: "validation" },
                                        [_vm._v(_vm._s(_vm.imageValidation))]
                                      )
                                    : _vm._e(),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row mt-3" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary ml-1",
                                style: {
                                  cursor: _vm.createDisabled
                                    ? "wait"
                                    : "pointer",
                                },
                                attrs: {
                                  type: "button",
                                  button: "",
                                  "aria-disabled": "true",
                                  disabled: _vm.createDisabled,
                                },
                                on: { click: _vm.createAdRequest },
                              },
                              [
                                _vm.createDisabled
                                  ? _c("span", {
                                      staticClass:
                                        "spinner-border spinner-border-sm",
                                      attrs: {
                                        role: "status",
                                        "aria-hidden": "true",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  "\n                                        Create Ad\n                                    "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.displaySuccess
              ? _c("div", [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("h3", { staticClass: "text-center text-success mb-4" }, [
                    _vm._v("Success!"),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-center mb-4" }, [
                    _vm._v("The Ad has been created!"),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        on: { click: _vm.displayAdForm },
                      },
                      [_vm._v("Create Another Ad")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { to: "/facebook/creator/adaccount" },
                        },
                        [_vm._v("Create a new Ad Account")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _vm.displayInfo
          ? _c(
              "div",
              { staticClass: "alert alert-info", attrs: { role: "alert" } },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.infoMessage) + "\n        "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.displayError
          ? _c(
              "div",
              { staticClass: "alert alert-danger", attrs: { role: "alert" } },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.errorMessage) + "\n        "
                ),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "text-center" }, [
      _c("strong", [_vm._v("OR")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", { staticClass: "display-1 text-success text-center" }, [
      _c("i", { staticClass: "fa fa-check-circle-o" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }