<template>
    <div class="component-container">
        <div class="row component-header">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-sm-8">
                        <i class="fa fa-chevron-left href" @click="backToCampaigns(selectedCampaign.adAccountId)"> Back to campaigns</i>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-10">
                        <h3 class="object-title">
                            {{selectedCampaign.name}}
                            <button class="btn header-button" title="Edit Campaign Details" data-toggle="modal" data-target="#campaignModal">
                                <i class="fa fa-edit"></i>
                            </button>
                        </h3>
                    </div>
                    <div class="col-sm-2 text-right">
                        <button class="btn btn-primary" @click="createNewAdset"><i class="fa fa-plus"></i> Adset</button>
                        <button class="btn btn-primary" title="Refresh Ad Sets" @click="getCampaignAdsets()"><i class="fa fa-refresh"></i></button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <label for="filterText" class="col-form-label text-input-label">Search Ad sets:</label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Search Adsets" aria-label="Search" v-model="filterText" aria-describedby="button-addon2">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div v-if="!adsetsLoaded" class="text-center">
                    <div class="spinner-border m-5" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <h3 v-else-if="(adsets.length === 0 || adsets[0].name === null) && adsetsLoaded">
                    No adsets listed
                </h3>
                <table class="campaign-detail-table table-scroll table campaign-single" v-else>
                    <thead>
                    <tr>
                        <th scope="col" class="pointer empty"></th>
                        <th scope="col" class="pointer name" @click="sortByKey('name')">Name</th>
                        <th scope="col" class="pointer status" @click="sortByKey('status')">Status</th>
                        <th scope="col" class="pointer" @click="sortByKey('createDate')">Created</th>
                        <th scope="col" class="actions">Actions</th>
                    </tr>
                    </thead>
                    <tbody class="campaign-detail-tbody body-half-screen"> 
                        <template v-for="(adset, index) in filteredAdsets">
                            <tr :class="[getShowClass(index), getGreyClass(index)]" :key=adset.id>
                                <td data-toggle="collapse" :data-target="'.adset-'+adset.id" @click="displayAdsetAds(adset.id)" class="collapsed tbody-empty"><div class="fa accordion-chevron"></div></td>
                                <th class="tbody-name">{{adset.name}}</th>
                                <td><div :class="[adset.status === 'ACTIVE' ? 'active-chip' : 'paused-chip']">{{adset.status}}</div></td>
                                <td>{{adset.createDate.toLocaleString()}}</td>
                                <td>
                                    <a class="wizard-more" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fa fa-ellipsis-h"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <button class="dropdown-item" type="button" @click="clickEditAdset(adset.id)">Edit</button>
                                        <button class="dropdown-item" type="button" @click="clickDuplicateAdset(adset.id)">Duplicate</button>
                                        <button class="dropdown-item" type="button" @click="clickDeleteAdsetModal(adset.id)" data-toggle="modal" data-target="#adsetDeleteModal">Delete</button>
                                        <button class="dropdown-item" @click="changeAdsetStatus(adset.id)">{{adset.status === "ACTIVE" ? "Pause" : "Set Active"}}</button>
                                        <button class="dropdown-item" type="button" @click="routeToAd(undefined , adset.id, editorStatus.Create)">Create a New Ad</button>
                                    </div>
                                </td>
                            </tr>
                            <tr :key=adset.key :class="[`adset-${adset.id}`, `collapse`]">
                                <td colspan="5">
                                    <div v-if="adsRequests[index] === false" class="text-center">
                                        <div class="spinner-border m-2" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    <p v-else-if="adset.Ads.length === 0">There are no ads listed</p>
                                    <table class="table campaign-single" v-else>
                                        <tbody class="adset-tbody">
                                            <tr v-for="(ad,adIndex) in adset.Ads" :key="ad.key" :class="[getShowClass(index), getGreyClass(adIndex)]">
                                                <td class="empty"></td>
                                                <td class="tbody-name">{{ad.name}}</td>
                                                <td><div :class="[ad.status === 'ACTIVE' ? 'active-chip' : 'paused-chip']">{{ad.status}}</div></td>
                                                <td></td>
                                                <td> 
                                                    <a class="wizard-more" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="fa fa-ellipsis-h"></i>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-menu-right">
                                                        <button class="dropdown-item" type="button" @click="routeToAd(ad.id, adset.id, editorStatus.Update)">Edit</button>
                                                        <button class="dropdown-item" type="button" @click="routeToAd(ad.id, adset.id, editorStatus.Duplicate)">Duplicate</button>
                                                        <button class="dropdown-item" type="button" @click="clickDeleteAdModal(adset.id, ad.id)" data-toggle="modal" data-target="#adDeleteModal">Delete</button>
                                                        <button class="dropdown-item" @click="changeAdStatus(ad.id, adset.id)">{{ad.status === "ACTIVE" ? "Pause" : "Set Active"}}</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <div class="modal fade" id="adsetDeleteModal" tabindex="-1" role="dialog" aria-labelledby="adsetModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="campaignLabel">Delete Adset</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group">
                                <h4>Are you sure you want to delete {{this.selectedAdset.name}}</h4>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" data-dismiss="modal" @click="clickDeleteAdset">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="adDeleteModal" tabindex="-1" role="dialog" aria-labelledby="adModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="campaignLabel">Delete Ad</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group">
                                <h4>Are you sure you want to delete {{this.selectedAd.name}}</h4>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" data-dismiss="modal" @click="clickDeleteAd">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CampaignModal :passedCampaign="selectedCampaign" :campaignModalStatus="campaignModalStatus" />
    </div>
</template>

<style lang="scss">
  @import '../../style/component/campaignDetail.scss';
</style>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { Campaign } from '../models/Campaign';
import { Adset } from '../models/Adset';
import { EditorStatus } from '../enums/EditorStatus';
import CampaignModal from '../components/CampaignModal.vue';
import { Ad } from "../models/Ad";

@Component({
    name: 'CampaignDetail',
    components: {
        CampaignModal
    }
})
export default class CampaignDetail extends Vue {
    @Prop() campaignId!: string;

    editorStatus = EditorStatus;    
    campaignModalStatus: EditorStatus = EditorStatus.Update;
    filterText: string = "";

    selectedAdset: Adset = new Adset();
    selectedAd : Ad = new Ad();

    adsetsLoaded : boolean = false;

    //sorting adset parameters
    currentSort: string = "";
    ascendingOrder: boolean = true;

    //request trackers
    adsRequests : boolean[] = new Array();

    adsets: Adset[] = new Array();

    async created() {
        await this.$store.dispatch('campaigns/setDetailedSelectedCampaignById', this.campaignId);
        this.getCampaignAdsets();
    }

    get filteredAdsets() {
        let filteredAdsets = this.adsets;
        if(this.filterText !== "")
        {
            filteredAdsets = filteredAdsets.filter((a : any) => {
                return a.name.toLowerCase().indexOf(this.filterText.toLowerCase()) !== -1
            });
        }
        return filteredAdsets;
    }

    get selectedCampaign() {
        return this.$store.getters['campaigns/selectedCampaign'];
    }

    getShowClass(index: number) : string {
        return index === 0 ? 'show': '';
    }

    getGreyClass(index : number) : string {
        return (index % 2 === 1 ? "table-row-grey" : "");
    }

    sortByKey(key : string) {
        this.adsets = this.adsets.sort(this.compareValues(key));
    }

    compareValues(key : string)  {
        if(this.currentSort === key)
            this.ascendingOrder = !this.ascendingOrder;
        else
            this.ascendingOrder = true;

        this.currentSort = key;
        let _This = this;
        return function(a : any, b : any) {
            let varA = (typeof a[key] === 'string') ? a[key].toLowerCase() : a[key];
            let varB = (typeof b[key] === 'string') ? b[key].toLowerCase() : b[key];
            let comparison = 0;
            if(varA > varB) {
                comparison = 1;
            }
            else if(varA < varB) {
                comparison = -1;
            }
            return _This.ascendingOrder ? comparison : comparison * -1;
        }
    }

    getCampaignAdsets() {
        this.$store.dispatch('adset/getSelectedAdsetByCampaignId', this.campaignId)
        .then((response : any) => {
            this.adsets = response;
            this.adsetsLoaded = true;
            for(let ind = 0; ind < this.adsets.length; ind++) {
                this.adsRequests.push(false);
            }
            if((this.adsets.length > 0 && this.adsets[0].name !== null)) {
                this.getAdsetAds(this.adsets[0].id);
            }
        }).catch((error : any) => {
            this.$toasted.global.error(`An error occurred retrieving the adsets.<br/> ${error}`);
        });
    }

    getAdsetAds(adsetId : string) {
        let adset : Adset = <Adset>this.adsets.filter((adset) => { return adset.id === adsetId; })[0];
        let adsetIndex = this.adsets.indexOf(adset);
        
        this.$store.dispatch("ad/getAdsByAdsetId", adsetId)
        .then((response: any) => {
            adset.Ads = response;
            adset.AdsLoaded = true;
            this.selectedAdset.AdsLoaded = true;
            this.adsRequests[adsetIndex] = true;
            this.adsets.splice(adsetIndex,1,adset);
        }).catch((error : any) => {
            this.$toasted.global.error(`An error occurred retrieving the ads.<br/> ${error}`);
        });
    }

    displayAdsetAds(adsetId : string) {
        let adset : Adset = <Adset>this.adsets.filter((adset) => { return adset.id === adsetId; })[0];
        if(!adset.AdsLoaded)
            this.getAdsetAds(adsetId);
    }

    clickEditAdset(adsetId : string) : void {
        this.$router.push({ name: 'adsetEdit', params: { adsetEditMode: EditorStatus.Update, adsetId: adsetId, campaignId: this.selectedCampaign.id } });
    }

    clickDuplicateAdset(adsetId: string) : void {
        this.$store.dispatch('adset/queueDeepCopyAdSet', adsetId)
            .then((success: any)=> {
                if(success) {
                    this.$toasted.success("Ad set successfully queued for duplication. Allow for a few seconds for the process to complete and then click the refresh button to see the new ad set");
                }
                else{
                    this.$toasted.global.error(`An error occurred duplicating the ad set.`);
                }
            }).catch((error : any)=>{
                this.$toasted.global.error(`An error occurred duplicating the ad set.<br/> ${error}`);
            });
    }

    routeToAd(adId : string, adsetId : string, editorStatus : EditorStatus) : void {
        this.$router.push({ name: 'adEdit', params: { adId: adId, adEditorStatus: editorStatus, adsetId: adsetId }});
    }

    createNewAdset() {
        this.$router.push({ name: 'adsetEdit', params: { campaignId: this.campaignId, adsetEditMode: 'Create'}});
    }

    clickDeleteAdset() {
        this.$store.dispatch('adset/deleteAdset', this.selectedAdset)
            .then((success : any) => {
                if(success) {
                    this.$toasted.show(`${this.selectedAdset.name} has been deleted`);
                } else {
                    this.$toasted.global.error(`An error occurred deleting the adset`);
                }
            }).catch((error : any) => {
                this.$toasted.global.error(`An error occurred deleting the adset.<br/> ${error}`);
            });
    }
    clickDeleteAd() {
        this.$store.dispatch('ad/deleteAd', this.selectedAd)
            .then((success : any) => {
                if(success) {
                    this.$toasted.show(`${this.selectedAd.name} has been deleted`);
                    this.$store.dispatch("ad/getAdsByAdsetId", this.selectedAdset.id)
                        .then((ads:any) => {
                            this.selectedAdset.Ads = ads;
                        });
                } else {
                    this.$toasted.global.error(`An error occurred deleting the ad`);
                }
            }).catch((error : any) => {
                this.$toasted.global.error(`An error occurred deleting the ad.<br/> ${error}`);
            });
    }

    clickDeleteAdsetModal(adsetId: string) : void {
        this.selectedAdset = <Adset>this.adsets.find((a) => { return a.id === adsetId; });
    }

    clickDeleteAdModal(adsetId : string, adId: string) : void {
        this.setSelectedAd(adsetId, adId);
    }

    setSelectedAd(adsetId : string, adId: string) : void {
        this.selectedAdset = <Adset>this.adsets.find((a) => { return a.id === adsetId; });
        this.selectedAd = <Ad>this.selectedAdset.Ads.find((ad : any) => ad.id === adId );
    }

    async changeAdsetStatus(adsetId: string) {
        let adset = new Adset(); 
        let adsetFromList : Adset = <Adset>this.adsets.find((a) => { return a.id === adsetId; });
        adset.id = adsetId;
        adset.status = adsetFromList.status === "ACTIVE" ? "PAUSED" : "ACTIVE";
        this.$store.dispatch('adset/updateAdsetStatus', {adsetId: adsetId, type: "adset", status: adset.status})
            .then(() => {
                adsetFromList.status = adset.status;            
            })
            .catch((error : any) => {
                this.$toasted.global.error(`An error occurred trying to update the adset.<br/> ${error}`);
            });
    }

    async changeAdStatus(adId : string, adsetId: string) {
        this.setSelectedAd(adsetId, adId);
        let ad = new Ad();
        ad.id = adId;

        ad.status = this.selectedAd.status === "ACTIVE" ? "PAUSED" : "ACTIVE";
        this.$store.dispatch('ad/updateAdStatus', {adId: adId, type: "ad", status: ad.status})
            .then(() => {
                this.selectedAd.status = ad.status;
            })
            .catch((error : any) => {
                this.$toasted.global.error(`An error occurred trying to update the ad.<br/> ${error}`);
            });
    }

    backToCampaigns(id : string) {
        this.$router.push({ name : 'adAccountsDetails', params: { adAccountId: id.replace('act_', '') }});
    }
}
</script>