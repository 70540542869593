import { Adset } from "../../models/Adset";
import { Campaign } from "../../models/Campaign";
import { IAdsetState } from '../Interfaces/IAdsetState';

const adsetStore = ({
    namespaced: true,
    state: {
        EditingAdset: false,
        Adsets: new Array<Adset>(),
        Interests: new Array(),
        Audiences: new Array(),
        Locales: new Array()
    } as IAdsetState,
    getters: {
        getAdsets(state: IAdsetState) {
            return state.Adsets;
        },
        interests(state: IAdsetState) {
            return state.Interests;
        },
        audiences(state: IAdsetState) {
            return state.Audiences;
        },
        locales(state: IAdsetState) {
            return state.Locales;
        }
    },
    mutations: {
        putAdset(state : IAdsetState, adsets : Adset[]) {
            state.Adsets = adsets;
        },
        putInterests(state: IAdsetState, interests: any) {
            state.Interests = interests;
        },
        putAudiences(state: IAdsetState, audiences: any) {
            state.Audiences = audiences;
        },
        putLocales(state: IAdsetState, locales: any) {
            state.Locales = locales;
        },
        processUpdatedAdset(state : IAdsetState, adsetObj : {adset:Adset , status:string}) {
            let adsetIndex = state.Adsets.findIndex((a) => { return a.id === adsetObj.adset?.id; });

            if (adsetObj.status.toLowerCase() === 'delete') {
                state.Adsets.splice(adsetIndex, 1);
            } else if (adsetObj.status.toLowerCase() === 'update') {
                state.Adsets.splice(adsetIndex, 1, adsetObj.adset);
            } else if (adsetObj.status.toLowerCase() === 'create') {
                state.Adsets.push(adsetObj.adset);
            };
        },
    },
    actions: {
        async getSelectedAdsetByCampaignId({commit,rootState, rootGetters}:{commit:any,rootState:any, rootGetters: any}, campaignId: string) {
            let selectedCampaign : Campaign = rootGetters['campaigns/selectedCampaign'];
            let adsetsByCampaignId : Adset[] = await rootState.facebookMarketingFactory.GetAdsetsByCampaignId(selectedCampaign.id);
            commit('putAdset', adsetsByCampaignId);
            return adsetsByCampaignId;
        },
        async getAdsetById({state,rootState}:{state:any,rootState:any}, adsetId: string) {
            let selectedAdsetIndex : number = state.Adsets.findIndex((adset : Adset) => adset.id === adsetId);
            let selectedAdset : Adset = state.Adsets[selectedAdsetIndex];

            if(selectedAdset && selectedAdset.isDetailed) {
                return selectedAdset;
            } else {
                let detailedAdset : Adset = await rootState.facebookMarketingFactory.GetAdsetById(adsetId)
                state.Adsets.splice(selectedAdsetIndex, 1, detailedAdset);
                return detailedAdset;
            }
        },
        async getInterests({commit,state,rootState}:{commit:any,state:any,rootState:any}) {
            if(state.Interests === undefined || state.Interests.length === 0) {
                await rootState.facebookMarketingFactory.GetInterests()
                .then((response : any) => {
                    commit('putInterests', response);
                });
            }
            return state.Interests;
        },
        async getAudiences({commit,state,rootState}:{commit:any,state:any,rootState:any}, adAccountId: string) {
            if(state.Audiences === undefined || state.Audiences.length === 0) {
                await rootState.facebookMarketingFactory.GetAudiences(adAccountId)
                .then((response : any) => {
                    commit('putAudiences', response);
                });
            }
            return state.Audiences;
        },
        async getLocales({commit,state,rootState}:{commit:any,state:any,rootState:any}) {
            if(state.Locales === undefined || state.Locales.length === 0) {
                await rootState.facebookMarketingFactory.GetAdSetLocales()
                .then((response : any) => {
                    commit('putLocales', response);
                });
            }
            return state.Locales;
        },
        async updateAdset({commit, rootState} : {commit:any, rootState: any}, adset : Adset) {
            var success = await rootState.facebookMarketingFactory.UpdateAdset(adset)
                if(success) {
                    commit('processUpdatedAdset', {adset: adset, status: "update"});
                }
            return success;
        },
        async createAdset({commit, rootState} : {commit:any, rootState: any}, adset : Adset) {
            var success = await rootState.facebookMarketingFactory.CreateAdset(adset)
                if(success) {
                    commit('processUpdatedAdset', {adset: adset, status: "create"});
                }
            return success;
        },
        async deleteAdset({commit, rootState} : {commit:any, rootState: any}, adset : Adset) {
            var success = await rootState.facebookMarketingFactory.DeleteAdset(adset.id)
                if(success) {
                    commit('processUpdatedAdset', {adset: adset, status: "delete"});
                }
            return success;
        },
        async queueDeepCopyAdSet({rootState} : {rootState: any}, adsetId : Adset) {
            var result = await rootState.facebookMarketingFactory.QueueDeepCopyAdSet(adsetId)
                .then((response: any) => {
                    return true;
                })
                .catch(() => {
                    return false;
                });
            return result;
        },
        async updateAdsetStatus({rootState}: {rootState: any}, adsetObj : {adsetId: string, type: string, status: string}) {
            await rootState.facebookMarketingFactory.UpdateStatus(adsetObj.adsetId, adsetObj.type, adsetObj.status);
        }
    }
})

export default adsetStore