<template>
    <div class="component-container">
        <div class="row header-no-navigation component-header">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-sm-10 inline-display-box">
                        <h3 class="object-title">Ad Accounts</h3>
                    </div>
                </div>
                <div class="row row-padding-margins">
                    <div class="input-group mb-3">
                        <label for="filterText" class="col-form-label text-input-label">Search Ad Accounts:</label>
                        <span class="input-group mb-3">
                            <input v-model="filterText" type="search" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div v-if="!adAccountsRequested" class="text-center">
                    <div class="spinner-border m-5" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <table v-else class="ad-accounts-table table-scroll table table-striped">
                    <thead>
                      <tr>
                        <th scope="col" class="pointer name" @click="sortByKey('adAccounts')">Name</th>
                      </tr>
                    </thead>
                    <tbody class="ad-accounts-tbody body-half-screen"> 
                        <tr v-for="adAccount in filteredAdAccounts" :key="adAccount.id">
                            <th scope="row" class="href tbody-adAccount" @click="goToAdAccount(adAccount.id)">{{adAccount.name}}</th>
                        </tr>
                        <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId">
                            <div slot="no-more">{{this.infiniteLoadResult}}</div>
                        </infinite-loading>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
  @import '../../style/component/adAccounts.scss';
</style>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import InfiniteLoading from 'vue-infinite-loading';

@Component({
    name: 'AdAccounts',
    components : {
        InfiniteLoading
    }
})
export default class AdAccounts extends Vue{
    page: number = 1;
    pageCount: number = 20;
    infiniteId: Date = new Date();
    filteredAdAccountLength: number = 0;

    //request trackers
    adAccountsRequested: boolean = false;

    //sorting campaign parameters
    currentSortType: string = "";
    ascendingOrder: boolean = true;

    async created() {
        this.getAdAccounts();
    }
    get filterText() {
        return this.$store.state.adAccounts.filterText;
    }
    set filterText(filterText : string) {
        this.resetInfiniteScroll();
        this.$store.dispatch('adAccounts/setFilterText', filterText);
    }
    infiniteHandler($state : any) {
        if (this.filteredAdAccountLength != this.filteredAdAccounts.length)
            this.filteredAdAccountLength = this.filteredAdAccounts.length;
        else {
            $state.complete();
            return;
        }

        if (this.filteredAdAccounts.length > 0) {
            this.page++;
            $state.loaded();
        }
        else {
            $state.complete();
        }
    }
    get infiniteLoadResult() {
        if (this.filteredAdAccounts.length === 0)
            return "No more results";
        else
            return "";
    }
    resetInfiniteScroll() {
        this.page = 1;
        this.filteredAdAccountLength = 0;
        this.infiniteId = new Date();
        if (document.getElementsByClassName("body-half-screen")[0])
            document.getElementsByClassName("body-half-screen")[0].scrollTop = 0;
    }

    get adAccounts() {
        return this.$store.getters["adAccounts/adAccounts"];
    }

    get filteredAdAccounts() {
        let filteredAdAccounts = this.adAccounts;
        if(this.filterText !== "") {
            filteredAdAccounts = filteredAdAccounts.filter((c : any) => {
                return c.name.toLowerCase().indexOf(this.filterText.toLowerCase()) !== -1;
            });
        }
        return filteredAdAccounts.slice(0, (this.page * this.pageCount));
    }

    sortByKey(key : string) {
        this.adAccounts.sort(this.compareValues(key));
    }

    compareValues(key : string)  {
        if(this.currentSortType === key)
            this.ascendingOrder = !this.ascendingOrder;
        else
            this.ascendingOrder = true;

        this.currentSortType = key;
        let _This = this;
        return function(a : any,b : any) {
            let varA = (typeof a[key] === 'string') ? a[key].toLowerCase() : a[key];
            let varB = (typeof b[key] === 'string') ? b[key].toLowerCase() : b[key];
            let comparison = 0;
            if(varA > varB) {
                comparison = 1;
            }
            else if(varA < varB) {
                comparison = -1;
            }
            return _This.ascendingOrder ? comparison : comparison * -1;
        }
    }

    async getAdAccounts() {
        this.adAccountsRequested = false;
        await this.$store.dispatch('adAccounts/setAllAdAccounts')
            .then(() => {
                this.resetInfiniteScroll();
            }).catch((error: any) => {
                this.$toasted.global.error(`An error occured getting the ad accounts.<br/> ${error}`);
            })
            .finally(() => {
                this.adAccountsRequested = true;
            });
    }

    goToAdAccount(id : string) {
        this.$router.push({ name : 'adAccountsDetails', params: { adAccountId : id }});
    }
}
</script>