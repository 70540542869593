export enum FacebookQuestionType {
    NONE = 0,
    CUSTOM = 1,
    CITY = 2,
    COMPANY_NAME = 3,
    COUNTRY = 4,
    DOB = 5,
    EMAIL = 6,
    GENDER = 7,
    FIRST_NAME = 8,
    LAST_NAME = 9,
    FULL_NAME = 10,
    JOB_TITLE = 11,
    MARITAL_STATUS = 12,
    PHONE = 13,
    POST_CODE = 14,
    PROVINCE = 15,
    RELATIONSHIP_STATUS = 16,
    STATE = 17,
    STREET_ADDRESS = 18,
    ZIP = 19,
    WORK_EMAIL = 20,
    MILITARY_STATUS = 21,
    WORK_PHONE_NUMBER = 22,
    DATE_TIME = 23,
}