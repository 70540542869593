<template>
    <div class="component-container">
        <div class="row component-header">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-sm-8">
                        <i class="fa fa-chevron-left href" @click="backToAdAccounts"> Back to ad accounts</i>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-10 inline-display-box">
                        <h3 class="object-title">{{selectedAdAccount.name}}</h3>
                    </div>
                    <div class="col-sm-2 text-right">
                        <button class="btn btn-primary" data-toggle="modal" data-target="#campaignModal" @click="createCampaignScreen"><i class="fa fa-plus"></i> Campaign</button>
                    </div>
                </div>
                <div class="row row-padding-margins">
                    <div class="col-sm-9 input-group mb-3">
                        <label for="filterText" class="col-form-label text-input-label">Search Campaigns:</label>
                        <span class="input-group mb-3">
                            <input v-model="filterText" type="search" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
                        </span>
                    </div>
                    <div class="col-sm-3">
                        <label for="status" class="col-form-label text-input-label">Status:</label>
                        <select class="form-control" :value="filterStatus" @change="updateFilterState($event)" :disabled="!campaignsRequested">
                            <option value="None">All</option>
                            <option value="Active">Active</option>
                            <option value="Paused">Paused</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div v-if="!campaignsRequested" class="text-center">
                    <div class="spinner-border m-5" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <table v-else class="campaigns-table table-scroll table table-striped">
                    <thead>
                      <tr>
                        <th scope="col" class="pointer name" @click="sortByKey('name')">Name</th>
                        <th scope="col" class="pointer status" @click="sortByKey('status')">Status</th>
                        <th scope="col" class="pointer create-date" @click="sortByKey('createDate')">Created</th>
                        <th scope="col" class="actions">Actions</th>
                      </tr>
                    </thead>
                    <tbody class="campaigns-tbody body-half-screen"> 
                        <tr v-for="campaign in filteredCampaigns" :key="campaign.id">
                            <th scope="row" class="href tbody-name" @click="goToCampaign(campaign.id)">{{campaign.name}}</th>
                            <td class="tbody-status"> <div :class="[campaign.status === 'ACTIVE' ? 'active-chip': 'paused-chip']">{{campaign.status}}</div></td>
                            <td class="tbody-create-date">{{campaign.createDate.toLocaleString()}}</td>
                            <td class="tbody-actions">
                                <a class="wizard-more" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fa fa-ellipsis-h"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <button class="dropdown-item" data-toggle="modal" data-target="#campaignModal" type="button" @click="editCampaignScreen(campaign.id)">Edit</button>
                                    <button class="dropdown-item" data-toggle="modal" data-target="#campaignModal" type="button" @click="duplicateCampaignScreen(campaign.id)">Duplicate</button>
                                    <button class="dropdown-item" data-toggle="modal" data-target="#campaignModal" type="button" @click="deleteCampaignScreen(campaign.id)">Delete</button>
                                    <button class="dropdown-item" @click="changeStatus(campaign.id)">{{campaign.status === "ACTIVE" ? "Pause" : "Set Active"}}</button>
                                </div>
                            </td>
                        </tr>
                        <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId">  
                            <div slot="no-more">{{this.infiniteLoadResult}}</div>
                        </infinite-loading>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- Modal -->
        <CampaignModal :passedCampaign="selectedCampaign"
            :campaignModalStatus="campaignModalStatus" 
            v-on:closed-modal="modalClosed" />
    </div>
</template>

<style lang="scss">
  @import '../../style/component/campaigns.scss';
</style>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Campaign } from '../models/Campaign';
import { AdAccount } from '../models/AdAccount';
import CampaignModal from '../components/CampaignModal.vue';
import InfiniteLoading from 'vue-infinite-loading';
import { EditorStatus } from '../enums/EditorStatus';

@Component({
    name: 'Campaigns',
    components : {
        CampaignModal,
        InfiniteLoading
    }
})
export default class Campaigns extends Vue{
    @Prop() adAccountId!: string;
    
    adAccounts: AdAccount[] = new Array();
    page: number = 1;
    pageCount: number = 20;
    infiniteId: Date = new Date();
    filteredCampaignLength: number = 0;

    //request trackers
    campaignsRequested: boolean = false;

    //sorting campaign parameters
    currentSortType: string = "";
    ascendingOrder: boolean = true;

    //modal parmeters
    campaignModalStatus: EditorStatus = EditorStatus.Create;
    
    async created() {
        await this.$store.dispatch('campaigns/setFilterStatus', "Active");
        await this.$store.dispatch('adAccounts/setSelectedAdAccountById', this.adAccountId);
        this.setAdAccountCampaigns();
    }
    infiniteHandler($state : any) {
        if (this.filteredCampaignLength != this.filteredCampaigns.length)
            this.filteredCampaignLength = this.filteredCampaigns.length;
        else {
            $state.complete();
            return;
        }

        if (this.filteredCampaigns.length > 0) {
            this.page++;
            $state.loaded();
        }
        else {
            $state.complete();
        }

    }
    get infiniteLoadResult() {
        if (this.filteredCampaigns.length === 0)
            return "No more results";
        else
            return "";
    }
    get filterText() {
        return this.$store.state.campaigns.filterText;
    }
    set filterText(filterText : string) {
        this.resetInfiniteScroll();
        this.$store.dispatch('campaigns/setFilterText', filterText);
    }
    get selectedAdAccountCampaigns() {
        return this.$store.getters["campaigns/getSelectedAdAccountCampaigns"];
    }
    get filterStatus() {
        return this.$store.getters["campaigns/filterStatus"];
    }
    get selectedAdAccount() {
        return this.$store.getters["adAccounts/selectedAdAccount"];
    }
    get selectedCampaign() {
        return this.$store.getters["campaigns/selectedCampaign"];
    }

    get filteredCampaigns() {
        let filteredCampaigns = this.selectedAdAccountCampaigns;
        
        if(this.filterStatus !== "None")
            filteredCampaigns = filteredCampaigns.filter((campaign: Campaign) => campaign.status.toLowerCase() === this.filterStatus.toLowerCase());

        if(this.filterText !== "") {
            filteredCampaigns = filteredCampaigns.filter((c: Campaign) => {
                return c.name.toLowerCase().indexOf(this.filterText.toLowerCase()) !== -1;
            });
        }
        return filteredCampaigns.sort(this.compareValues(this.currentSortType, this.ascendingOrder));
    }
    
    resetInfiniteScroll() {
        this.page = 1;
        this.filteredCampaignLength = 0;
        this.infiniteId = new Date();
        if (document.getElementsByClassName("body-half-screen")[0])
            document.getElementsByClassName("body-half-screen")[0].scrollTop = 0;
    }

    async updateFilterState(event : any) {
        await this.$store.dispatch('campaigns/setFilterStatus', event.currentTarget.value);
        var sortType = this.currentSortType !== "" ? this.currentSortType : "name";
        this.currentSortType = "";
        this.sortByKey(sortType);
        this.resetInfiniteScroll();
    }

    async setAdAccountCampaigns() {
        this.campaignsRequested = false;
        await this.$store.dispatch('campaigns/setCampaignsByAdAccountId', this.adAccountId);
        this.campaignsRequested = true;
    }

    sortByKey(key : string) {
        if(key === this.currentSortType)
            this.ascendingOrder = !this.ascendingOrder;
        else {
            this.currentSortType = key;
            this.ascendingOrder = true;
        }
    }

    compareValues(key : string, ascendingOrder : boolean)  {
        return function(a : any,b : any) {
            let varA = (typeof a[key] === 'string') ? a[key].toLowerCase() : a[key];
            let varB = (typeof b[key] === 'string') ? b[key].toLowerCase() : b[key];
            let comparison = 0;
            if(varA > varB) {
                comparison = 1;
            }
            else if(varA < varB) {
                comparison = -1;
            }
            return ascendingOrder ? comparison : comparison * -1;
        }
    }

    async modalClosed() {
        await this.$store.dispatch('campaigns/setDetailedSelectedCampaignById', "0");
    }

    async createCampaignScreen() {
        this.campaignModalStatus = EditorStatus.Create;
        await this.$store.dispatch('campaigns/setDetailedSelectedCampaignById', "0");
        this.resetInfiniteScroll();
    }
    async editCampaignScreen(campaignId : string) {
        this.campaignModalStatus = EditorStatus.Update;
        await this.$store.dispatch('campaigns/setDetailedSelectedCampaignById', campaignId)
    }
    async duplicateCampaignScreen(campaignId : string) {
        this.campaignModalStatus = EditorStatus.Duplicate;
        await this.$store.dispatch('campaigns/setDetailedSelectedCampaignById', campaignId)
    }
    async deleteCampaignScreen(campaignId : string) {
        this.campaignModalStatus = EditorStatus.Delete;
        await this.$store.dispatch('campaigns/setDetailedSelectedCampaignById', campaignId)
         this.resetInfiniteScroll();
    }

    async changeStatus(campaignId : string) {
        let campaign : Campaign = <Campaign>this.selectedAdAccountCampaigns.find((c : Campaign) => { return c.id === campaignId; });
        let campaignCopy = Campaign.CreateCopy(campaign);
        campaignCopy.status = campaign.status === "ACTIVE" ? "PAUSED" : "ACTIVE";
        if(campaignCopy.Validate().IsValid) {
            await this.$store.dispatch('campaigns/updateFilterStatus', campaignCopy);
            campaign.status = campaignCopy.status
        } else {
            this.$toasted.global.error(`An error occured updating the campaign`);
        }
        this.resetInfiniteScroll();
    }

    goToCampaign(id : string) {
        this.$router.push({ name : 'campaignDetails', params: { campaignId: id }});
    }

    backToAdAccounts() {
        this.$router.push({ name : 'adAccounts'});
    }
}
</script>