import { IPageState } from '../Interfaces/IPageState';

const pagesKey : string = "FacebookPages"

const pageStore = ({
    namespaced: true,
    state: {
        facebookPages : new Array(),
    } as IPageState,
    getters: {
        facebookPages(state: IPageState) {
            if(state.facebookPages.length === 0 && !(sessionStorage.getItem(pagesKey) === null || sessionStorage.getItem(pagesKey) === undefined)) {
                state.facebookPages = JSON.parse(<string>sessionStorage.getItem(pagesKey));
            }
            return state.facebookPages;
        },
    },
    mutations: {
        putfacebookPages(state : IPageState, pages : any) {
            sessionStorage.setItem(pagesKey, JSON.stringify(pages));
            state.facebookPages = pages;
        },
    },
    actions: {
        async getFacebookPages({commit,state,rootState,getters}:{commit:any,state:any,rootState:any, getters: any}) {
            let facebookPages : any = getters.facebookPages;
            if(facebookPages.length === 0) {
                await rootState.facebookMarketingFactory.GetFacebookPages().then((response : any) => {
                    commit('putfacebookPages', response);
                });
            }
            return facebookPages;
        },
    },
})

export default pageStore