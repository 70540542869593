import { FacebookObject } from "./FacebookObject";
import { ValidationResponse, PropertyError } from "./ValidationResponse";

export class Campaign extends FacebookObject {
    public createDate: Date = new Date;
    public adAccount: string = "";
    public adAccountId: string = "";
    public dailyBudget: number = 100;
    public bidStrategy: string = "LOWEST_COST_WITHOUT_CAP";
    public campaignObjective: string = "";
    public specialAdCategory: string = "HOUSING"
    public budgetOptimization: boolean = false;
    public isAdAccountOdax: boolean = false;
    public isDetailed: boolean = false;

    public static readonly AdAccountVal : string = "adAccountId";
    public static readonly DailyBudgetVal : string = "dailyBudget";

    constructor() {
        super();
        this.status = "PAUSED";
    }

    Validate(): ValidationResponse {
        let validationResponse = new ValidationResponse();

        this.checkPropertyError(this.nameValidation(), validationResponse);
        this.checkPropertyError(this.adAccountIdValidation(), validationResponse);
        this.checkPropertyError(this.dailyBudgetValidation(), validationResponse);
        
        validationResponse.IsValid = validationResponse.ErrorMessages.length === 0;
        return validationResponse;
    }

    private adAccountIdValidation() : PropertyError {
        return (this.adAccountId === "") ? new PropertyError(Campaign.AdAccountVal, "Ad Account is Required") : new PropertyError("", "");
    }
    private dailyBudgetValidation() : PropertyError {
        return (this.dailyBudget < 100) ? new PropertyError(Campaign.DailyBudgetVal,"Daily Budget must be at least $1") : new PropertyError("", "");
    }

    public static CreateCopy(camapignToCopy :Campaign) : Campaign {
        let copy = new Campaign();
        copy.id = camapignToCopy.id;
        copy.name = camapignToCopy.name;
        copy.status = camapignToCopy.status;
        copy.createDate = camapignToCopy.createDate;
        copy.adAccountId = camapignToCopy.adAccountId;
        copy.adAccount = camapignToCopy.adAccount;
        copy.dailyBudget = camapignToCopy.dailyBudget;
        copy.bidStrategy = camapignToCopy.bidStrategy;
        copy.campaignObjective = camapignToCopy.campaignObjective;
        copy.specialAdCategory = camapignToCopy.specialAdCategory;
        copy.budgetOptimization = camapignToCopy.budgetOptimization;
        copy.isAdAccountOdax = camapignToCopy.isAdAccountOdax;
        return copy;
    }
}