import { Campaign } from '../../models/Campaign';
import { AdAccount } from '../../models/AdAccount';
import { ICampaignState } from '../Interfaces/ICampaignState';

const campaignsKey : string = "campaigns";
const selectedCampaignKey : string = "selectedCampaign";

const campaignStore = ({
    namespaced: true,
    state: {
        selectedCampaign : new Campaign(),
        campaigns : new Array<Campaign>(),
        filterText : "" ,
        filterStatus: "Active"
    } as ICampaignState,
    getters: {
        selectedCampaign(state: ICampaignState) {
            if(state.selectedCampaign === null || state.selectedCampaign.id === "" && !(sessionStorage.getItem(selectedCampaignKey) === null || sessionStorage.getItem(selectedCampaignKey) === undefined)) {
                state.selectedCampaign = JSON.parse(<string>sessionStorage.getItem(selectedCampaignKey));
            }
            return state.selectedCampaign;
        },
        campaigns(state: ICampaignState) {
            if(state.campaigns.length === 0 && !(sessionStorage.getItem(campaignsKey) === null || sessionStorage.getItem(campaignsKey) === undefined)) {
                let campaigns = JSON.parse(<string>sessionStorage.getItem(campaignsKey));
                state.campaigns = campaigns;
            }
            state.campaigns = state.campaigns.map((campaign: Campaign) => {
                campaign.createDate = new Date(campaign.createDate);
                return campaign;
            });
            return state.campaigns;
        },
        filterText(state: ICampaignState) {
            return state.filterText;
        },
        filterStatus(state: ICampaignState) { 
            return state.filterStatus;
        },
        getSelectedAdAccountCampaigns(state: ICampaignState, getters: any, rootState : any) {
            return getters.campaigns.filter((campaign: Campaign) => campaign.adAccountId === `act_${rootState.adAccounts.selectedAdAccount.id}`);
        }
    },
    mutations: {
        setCampaigns(state: ICampaignState, campaigns : Campaign[]) {
            sessionStorage.setItem(campaignsKey, JSON.stringify(campaigns));
            state.campaigns = JSON.parse(<string>sessionStorage.getItem(campaignsKey));
        },
        putCampaigns(state : ICampaignState, campaigns : Campaign[]) {
            var sessionCampaignsValue = JSON.parse(<string>sessionStorage.getItem(campaignsKey));
            var sessionCampaigns : Campaign[];
            if(sessionCampaignsValue !== null)
                sessionCampaigns = JSON.parse(<string>sessionStorage.getItem(campaignsKey));
            else
                sessionCampaigns = new Array();
            
            sessionCampaigns = sessionCampaigns.concat(campaigns);
            sessionStorage.setItem(campaignsKey, JSON.stringify(sessionCampaigns));
            state.campaigns = JSON.parse(<string>sessionStorage.getItem(campaignsKey));
        },
        setFilterText(state : ICampaignState, filterText : string) {
            state.filterText = filterText;
        },
        setFilterStatus(state : ICampaignState, filterStatus : string) {
            state.filterStatus = filterStatus;
        },
        setSelectedCampaign(state:ICampaignState, selectedCampaign : Campaign) {
            sessionStorage.setItem(selectedCampaignKey, JSON.stringify(selectedCampaign));
            state.selectedCampaign = selectedCampaign;
        },
        processUpdatedCampaign(state : ICampaignState, campaignObj : {campaign:Campaign , status:string}) {
            let campaignIndex = state.campaigns.findIndex((c) => { return c.id === campaignObj.campaign?.id; });

            if (campaignObj.status.toLowerCase() === 'delete')
                state.campaigns.splice(campaignIndex, 1);
            else if (campaignObj.status.toLowerCase() === 'update') {
                state.campaigns.splice(campaignIndex, 1, campaignObj.campaign);
            }
            else if (campaignObj.status.toLowerCase() === 'create')
                state.campaigns.push(campaignObj.campaign);
        }
    },
    actions: {
        async setDetailedSelectedCampaignById({commit, state, rootState, getters} : {commit:any, state:any, rootState: any, getters: any}, campaignId : string) {
            if(campaignId === "0") //clears the selectedCampaign
            {
                commit("setSelectedCampaign", new Campaign());
            }
            else{
                let campaigns : Campaign[] = getters.campaigns;
                let selectedCampaignIndex : number = campaigns.findIndex((x: Campaign) => x.id.replace("act_", "") === campaignId);
                let selectedCampaign : Campaign = campaigns[selectedCampaignIndex];
                
                if (!(selectedCampaign && selectedCampaign.isDetailed)) {
                    let detailedCampaign : Campaign = await rootState.facebookMarketingFactory.GetCampaignById(selectedCampaign.id);
                    detailedCampaign.isAdAccountOdax = selectedCampaign.isAdAccountOdax;
                    detailedCampaign.adAccountId = selectedCampaign.adAccountId;
                    campaigns.splice(selectedCampaignIndex, 1, detailedCampaign);
                    selectedCampaign = detailedCampaign;
                    commit("setCampaigns", campaigns);
                }
                commit("setSelectedCampaign", selectedCampaign);
            }
        },
        async getCampaignById({commit, state, rootState} : {commit:any, state:any, rootState: any}, campaignId : string) {
            let selectedCampaignIndex : number = state.campaigns.findIndex((x: Campaign) => x.id === campaignId);
            let selectedCampaign : Campaign = state.campaigns[selectedCampaignIndex];

            if(selectedCampaign && selectedCampaign.isDetailed)
                return selectedCampaign;
            else{
                let detailedCampaign : Campaign = await rootState.facebookMarketingFactory.GetCampaignById(campaignId);
                detailedCampaign.isAdAccountOdax = selectedCampaign.isAdAccountOdax;
                detailedCampaign.adAccountId = selectedCampaign.adAccountId;
                state.campaigns.splice(selectedCampaignIndex, 1, detailedCampaign);
                return detailedCampaign;
            }
        },
        async setCampaignsByAdAccountId({commit, rootState, rootGetters, getters} : {commit:any, state:any, rootState: any, rootGetters :any, getters :any}, adAccountId : string) {
            let campaigns = getters.campaigns;
            let selectedAdAccount : AdAccount = rootGetters['adAccounts/selectedAdAccount'];
            let selectedCampaignsByAdAccountId : Campaign[] = campaigns.filter((campaign: Campaign) => campaign.adAccountId.replace("act_", "") === selectedAdAccount.id);
            
            if(selectedCampaignsByAdAccountId.length === 0) {
                let adAccounts = rootGetters['adAccounts/adAccounts'];
                let campaignsByAccountId : Campaign[] = await rootState.facebookMarketingFactory.GetCampaignsByAdAccountId(adAccountId);
                commit('putCampaigns', campaignsByAccountId);
            }
        },
        async filterCampaignByStatus({commit}:{commit:any}) {
            commit('filterCampaignByStatus');
        },
        async setFilterStatus({commit}:{commit:any}, filterStatus : string) {
            commit('setFilterStatus', filterStatus);
        },
        async setFilterText({commit}:{commit:any}, filterText : string) {
            commit('setFilterText', filterText);
        },
        async updateStoreCampaigns({commit}:{commit:any}, campaignObj : {campaign:Campaign , status:string}) {
            commit('processUpdatedCampaign', campaignObj);
        },
        async createCampaign({commit, rootState} : {commit:any, state:any, rootState: any}, campaign : Campaign) {
            await rootState.facebookMarketingFactory.CreateCampaign(campaign)                
                .then((id : string) => {
                    if(id && id !== "") {
                        campaign.id = id;
                        commit('processUpdatedCampaign', {campaign: campaign, status: "create"});
                    }
                });
        },
        async updateCampaign({commit, rootState} : {commit:any, state:any, rootState: any}, campaign : Campaign) {
            await rootState.facebookMarketingFactory.UpdateCampaign(campaign)                
                .then((success : any) => {
                    if(success) {
                        commit('processUpdatedCampaign', {campaign: campaign, status: "update"});
                    }
                });
        },
        async deleteCampaign({commit, rootState} : {commit:any, state:any, rootState: any}, campaign : Campaign) {
            await rootState.facebookMarketingFactory.DeleteCampaign(campaign.id)                
                .then((success : any) => {
                    if(success) {
                        commit('processUpdatedCampaign', {campaign: campaign, status: "delete"});
                    }
                });
        },
        async updateFilterStatus({rootState} : {state:any, rootState: any}, campaign : Campaign) {
            await rootState.facebookMarketingFactory.UpdateStatus(campaign.id, "campaign", campaign.status)
        }
    }
});

export default campaignStore;