<template>
    <div class="d-flex">
        <Sidebar/>
        <div class="component-container-sidebar">
            <h6 class="ms-3 mt-3">Account ID: {{ creatorInfo.AdAccountId.substring(4) }}</h6>
            <h6 class="ms-3">Campaign ID: {{ selectedCampaign.CampaignId }}</h6>
            <div class="card mb-3 mt-3" >
                <div class="card-header">
                    Adset Creator
                </div>
                <div class="card-body">
                    <form v-if=!displaySuccess class="needs-validation" novalidate :class="formValidationClass">
                        <div class="row mb-3">
                            <div :class="columnClassByCampaignType()">
                                <label for="startDate" class="form-label">Start Date</label>
                                <input @change="(event) => setStartDate(event)" type="date" class="form-control" id="startDate" :value="getStartDateString()" required>
                            </div>
                            <div :class="columnClassByCampaignType()">
                                <label for="pageId" class="form-label">Page ID</label>
                                <input v-model="pageId" type="text" class="form-control" id="pageId" placeholder="Page ID" required>
                            </div>          
                            <div v-if="displayBuyerInput()" :class="columnClassByCampaignType()">
                                <label for="location" class="form-label">Location Name</label>
                                <input v-model="location" type="text" class="form-control" id="location" placeholder="Enter location here" required>
                            </div>
                        </div>
                        <div v-if="displayCatalogAndProductInput()" class="row mb-2">
                            <ProductSetSelector @product-set-changed="updateProductSetIdandCatalogId" />
                        </div>
                        <div v-if="isListCastAdSet()" class="row mb-1">
                            <div class="col-md-12 mb-3">
                                <label class="form-label" for="dynamicAudienceId">Data Source Pixels</label>
                                <div class="card" :class="listCastDataSourcePixelClass">
                                    <div class="card-body">
                                        <div class="row">
                                            <div v-for="pixelId in pixelIds" v-bind:key="pixelId[1]" class="col-md-3 mb-1">
                                                <div class="btn-group" data-bs-toggle="buttons">
                                                    <label style="width:150px; font-size: 13px; font-weight:400;" class="btn btn-outline-secondary" :class="isPixelActive(pixelId[1])" @click=pixelClick(pixelId[1])>
                                                        {{ pixelId[0] }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <LocationSelector :locationSelectorResults=locations :locationValidation=locationValidation />
                            </div>
                        </div>
                        <div class="form-row mt-3">
                            <button type="button" class="btn btn-primary ml-1" aria-disabled="true" :disabled=createDisabled @click="createAdsetRequest" :style="{ 'cursor': createDisabled ? 'wait' : 'pointer' }">
                                <span v-if="createDisabled" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Create Adset
                            </button>
                        </div>
                    </form>
                    <div v-if=displaySuccess>
                        <p class="display-1 text-success text-center"><i class="fa fa-check-circle-o"></i></p>
                        <h3 class="text-center text-success mb-4">Success!</h3>
                        <p class="text-center mb-4">The Adset has been created!</p>
                        <p v-if="isNotSeller()" class="text-center"><router-link class="btn btn-primary" to="/facebook/creator/form">Continue to Form Creation/Selection</router-link></p>
                        <p v-else class="text-center"><router-link class="btn btn-primary" to="/facebook/creator/ad">Continue to Ad Creation</router-link></p>
                        <p class="text-center"><router-link class="btn btn-secondary" to="/facebook/creator/adaccount">Create a new Ad Account</router-link></p>
                    </div>
                </div>
            </div>
            <div class="alert alert-info" role="alert" v-if=displayInfo>
                {{ infoMessage }}
            </div>
            <div class="alert alert-danger" role="alert" v-if=displayError>
                {{ errorMessage }}
            </div>
        </div>
    </div>
    
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { AdsetCreationRequest } from '../../models/AdsetCreationRequest';
import { CreatorInfoAdset } from '../../models/CreatorInfo';
import { ValidationResponse } from '../../models/ValidationResponse';
import { LocationSelectorResults } from '../../models/Locations';
import LocationSelector from '../LocationSelector.vue';
import ProductSetSelector from '../ProductSetSelector.vue';
import { Adset } from '../../models/Adset';
import Sidebar from './Sidebar.vue';


@Component({
    name: 'AdsetCreator',
    components:{
        LocationSelector, ProductSetSelector, Sidebar
    }
})
export default class AdsetCreator extends Vue{

    mounted() {
        this.form = document.getElementsByClassName('needs-validation')[0];
    }

    pixelIds: [string, string][] =[ 
            [ "Alabama", "1221746192103614"], [ "Alaska", "299780179259788" ], [ "Arizona", "520752926537681" ], [ "Arkansas", "496029009287346" ], [ "California", "1083202208994446" ], [ "Colorado", "1669267900183112" ], [ "Connecticut", "1195015307720824" ], 
            [ "Delaware", "880210506883394" ], [ "Florida", "741277860324922" ], [ "Georgia", "475389121119022" ], [ "Hawaii", "3177590372541571" ], [ "Idaho", "5366472573441501" ],
            [ "Illinois", "697561241823535" ], [ "Indiana", "726835355473514" ], [ "Iowa", "653435596169642" ], [ "Kansas", "502480175296366" ], [ "Kentucky", "622401225962147" ], [ "Louisiana", "852146852673413" ], 
            [ "Maine", "884623673139437" ], [ "Maryland", "312517024414693" ], [ "Massachusetts", "656919062500276" ], [ "Michigan", "256264920062192" ], [ "Minnesota", "437859601720705" ], [ "Mississippi", "889962508866800" ], 
            [ "Missouri", "833126397880298" ], [ "Montana", "856191528789016" ], [ "Nebraska", "1799081333941374" ], [ "Nevada", "6400892819925158" ], [ "New Hampshire", "1327074411425839" ], [ "New Jersey", "558775309482715" ], 
            [ "New Mexico", "485198360224094" ], [ "New York", "437375675099779" ], [ "North Carolina", "5622125697843433" ], [ "North Dakota", "933256464300896" ], [ "Ohio", "560600086097980" ], [ "Oklahoma", "1384251119013798" ], 
            [ "Oregon", "415621120766599" ], [ "Pennsylvania", "531486935474546" ], [ "Rhode Island", "438217201621943" ], [ "South Carolina", "392705809672412" ], [ "South Dakota", "782449730262971" ], 
            [ "Tennessee", "436169841831222" ], [ "Texas", "3286537198261750" ], [ "Utah", "183885431018917" ], [ "Vermont", "391344686612531" ], [ "Virginia", "1479521489175871" ], [ "Washington", "3375084656071892" ], 
            [ "West virginia", "2035150796883713" ], [ "Wisconsin", "175412851968721" ], [ "Wyoming", "861208801754614" ],[ "Alberta", "453585559980746" ], [ "British Columbia", "371842568414692" ], [ "Ontario", "490002382553616"],
            [ "CINC ListCast", "491454861378391"]
    ];

    // this property should be updated to bigint
    pageId: string = "";
    locations : LocationSelectorResults = new LocationSelectorResults();
    productsets: string[] = [];
    startDate!: Date;
    
    productSetId: string = "";
    catalogId: string = "";
    location: string = "";
    selectedPixelIds: string[] = [];
    createDisabled: boolean = false;
    form: any;
    displayInfo: boolean = false;
    displayError: boolean = false;
    displaySuccess: boolean = false;
    infoMessage: string = '';
    errorMessage: string = '';
    listCastDataSourcePixelClass: string = "";
    formValidationClass: string = "";    

    

    validation : ValidationResponse = new ValidationResponse();

    getStartDateString() : string {
        if(!this.startDate) {
            this.startDate = new Date(Date.now());
        }
        return this.startDate.toISOString().split('T')[0];
    }
    setStartDate(event: any) : void {
        this.startDate = new Date(event.target.value);
    }

    updateProductSetIdandCatalogId(newProductSetId: string, newCatalogId: string) {
        this.productSetId = newProductSetId;
        this.catalogId = newCatalogId;
    }

    get selectedCampaign() {
        return this.$store.getters['creator/selectedCampaign'];          
    }

    get creatorInfo() {
        return this.$store.state.creator.creatorInfo
    }

    get locationValidation() {
        return this.validation.getValidation(Adset.LocationsVal);
    }

    pixelClick(id: string) {
        if(this.selectedPixelIds.includes(id))
            this.selectedPixelIds = this.selectedPixelIds.filter(pixelId => pixelId !== id);
        else
            this.selectedPixelIds.push(id);
    }

    isPixelActive(id: string) {
        return this.selectedPixelIds.includes(id) ? 'active' : '';
    }

    async createAdsetRequest(e: Event) {
        this.formValidationClass = "";
        if(this.selectedPixelIds.length === 0 && this.isListCastAdSet()){
            this.listCastDataSourcePixelClass = "border-danger";
            this.form.checkValidity();
            this.formValidationClass = "was-validated";
            return;
        }
        else{
            this.listCastDataSourcePixelClass = "";
        }

        this.displayError = false;
        if(this.form){
            e.preventDefault();
            e.stopPropagation();
            if (this.form.checkValidity() === true) { 
                let adsetCreationRequest = new AdsetCreationRequest();
                adsetCreationRequest.DomainName = this.creatorInfo.DomainName;
                adsetCreationRequest.AdAccountId = this.creatorInfo.AdAccountId;
                adsetCreationRequest.CampaignId = this.selectedCampaign.CampaignId;
                adsetCreationRequest.CampaignType = this.selectedCampaign.CampaignType;
                adsetCreationRequest.ClientFullName = this.creatorInfo.ClientFullName;
                adsetCreationRequest.PageId = this.pageId;
                adsetCreationRequest.Location = this.location;
                adsetCreationRequest.StartDate = this.startDate;
                adsetCreationRequest.Company = this.creatorInfo.Company;
                adsetCreationRequest.ProductSetId = this.productSetId;
                adsetCreationRequest.PixelIds = this.selectedPixelIds
                adsetCreationRequest.Cities = this.locations.cities;
                adsetCreationRequest.RegionIds = this.locations.regions.map(region => region.key); 
                adsetCreationRequest.Medium_Geo_Areas = this.locations.counties.map(county => county.key);

                this.displayInfo = true;
                this.infoMessage = 'Creating Adset...';
                this.createDisabled = true;
                this.$store.dispatch('creator/createCincConfiguredAdset', adsetCreationRequest)
                .then((response: any) => {
                    this.$store.dispatch('creator/setSelectedAdsetId', response.data.id);
                    this.$store.dispatch('creator/setActiveItem', response.data.id);
                    this.setCreatorInfo(adsetCreationRequest, response.data.id, response.data.name);
                    this.displaySuccess = true;
                })
                .catch((error: any) => {
                    this.displayError = true;
                    this.displayInfo = false;
                    this.createDisabled = false;
                    this.errorMessage = `An error occurred while creating the adset. Please try again or report the issue. ${error}`;
                }).finally(() => {
                    this.createDisabled = false;
                    this.infoMessage = '';
                    this.displayInfo = false;
                });
            }
            this.formValidationClass = "was-validated";
        }
        else{
            this.form = document.getElementsByClassName('needs-validation')[0];
            this.createAdsetRequest(e);
        }
    }

    displayCatalogAndProductInput() : boolean {
        return (this.selectedCampaign.CampaignType === 'TeamListingAds' || this.selectedCampaign.CampaignType === 'ListCast');
    }

    isListCastAdSet() : boolean {
        return this.selectedCampaign.CampaignType === 'ListCast';
    }

    isNotSeller() : boolean {
        return this.selectedCampaign.CampaignType !== 'Seller';
    }

    displayBuyerInput() :boolean {
        return this.selectedCampaign.CampaignType === 'Buyer';
    }

    columnClassByCampaignType() : string {
        //these are the bootstrap column classes, Seller currently displays 2 columns, Buyer displays 3, and the rest display 4
        //bootstrap columns are 12 units wide, so 12/2 = 6, 12/3 = 4, 12/4 = 3 
        if(this.selectedCampaign.CampaignType === 'Buyer') {
            return 'col-md-4';
        }
        else{
            return 'col-md-6';
        }
    }

    async setCreatorInfo(adsetCreationRequest : AdsetCreationRequest, adsetId: string, adsetName: string)
    {
        let adset : CreatorInfoAdset = {
            AdsetId: adsetId,
            AdsetName: adsetName,
            PageId: adsetCreationRequest.PageId,
            ProductSetId: adsetCreationRequest.ProductSetId,
            Ads: []
        }

        if (this.selectedCampaign) {
            this.selectedCampaign.Adsets.push(adset);
        }
        
        await this.$store.dispatch('creator/setCreatorInfo', this.creatorInfo);
    }
}
</script>
