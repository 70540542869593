import { Adset } from "../models/Adset";
import { Campaign } from "../models/Campaign";
import { AdAccount } from "../models/AdAccount";
import { IFacebookObject } from "../interfaces/IFacebookObject";
import { Config } from "../models/Config";
import { IMarketingFactory } from "../interfaces/IMarketingFactory";
import { Ad } from "../models/Ad";
import { LeadFormModel, TrackingParameter } from "../models/LeadForm";
import store from "../store/";
import { router } from "../index";

import axios from 'axios';
import { UpdateStatusRequest } from "../models/UpdateStatusRequest";
import { AdAccountCreationRequest } from "../models/AdAccountCreationRequest";
import { CampaignCreationRequest } from "../models/CampaignCreationRequest";
import { AdsetCreationRequest } from "../models/AdsetCreationRequest";
import { AdCreationRequest } from "../models/AdCreationRequest";
import { AdAccountPermissionsRequest } from "../models/AdAccountPermissionsRequest";
import { CreatorInfoCreationRequest, FormCreationRequest } from "../models/FormCreationRequest";
import { ProductCatalogSet } from '../models/ProductCatalogSet';
import { Company } from "../enums/Company";
import { CreatorInfo, CreatorInfoAd, CreatorInfoAdset, CreatorInfoCampaign } from "../models/CreatorInfo";
import { ReportCreationRequest } from "../models/ReportCreationRequest";

export class FacebookMarketingFactory implements IMarketingFactory {
    private config : Config = require("../../config.json");
    private readonly apiUrl : string = this.config.apiUrl;

    constructor(authorization : string) {
        axios.defaults.headers.common["Authorization"] = authorization;
    }

    async GetHeartbeat() : Promise<any> {
        return this.getOne('api/Heartbeat/', '', this.mapDirectResponse);
    }
    async CreateAccount(adAccount : AdAccount): Promise<any> {
        return this.create<AdAccount>(adAccount, "");
    }    
    async CreateCampaign(campaign : Campaign): Promise<any> {
        return this.create(campaign, `api/Campaign/`);
    }
    async CreateAdset(adset: Adset): Promise<any> {
        return this.create(adset, `api/Adset/`);
    }
    async CreateAd(ad: Ad): Promise<any> {
        return this.create(ad, `api/Ad/`);
    }
    async CreateShortLink(linkUrl : string) :Promise<any> {
        var linkObject = { longUrl : linkUrl };
        return this.createNonFBObject(linkObject, 'api/Utility/ShortenUrl/');
    }
    async CreateLeadForm(leadForm: LeadFormModel): Promise<any> {
        return this.create(leadForm, `api/Form/`);
    }
    async CreateAdPreview(ad: Ad) : Promise<any> {
        return axios
            .post(`${this.apiUrl}api/Ad/GeneratePreview/`, ad)
            .then(response => { 
                return response.data;
            })
            .catch((error : any) => {
                this.factoryErrorHandling(error);
                throw new Error(error.response.data);
            });
    }

    async SendReportRequest(reportRequest: ReportCreationRequest) {
        return axios
        .post(`${this.apiUrl}api/Report/Create`, reportRequest);
    }

    async CreateImageHash(byteString : string, adAccountId : string, company : Company) : Promise<any> {
        if (!adAccountId.includes('act_')) {
            adAccountId = 'act_' + adAccountId;
        }
        return axios
            .post(`${this.apiUrl}api/Image/`, { ImageBytes : byteString, AdAccountId: adAccountId, Company : company })
            .then(response => {
                return response.data;
            })
            .catch((error : any) => {
                this.factoryErrorHandling(error);
                throw new Error(error.response.data);
            });
    }

    async GrantAdAccountPermissions(request: AdAccountPermissionsRequest) : Promise<any> {
        var companyInt : number = +request.Company;
        return axios
            .post(`${this.apiUrl}api/Account/Configure/`, { AdAccountId : request.AdAccountId, Company: companyInt } )
            .then((response : any) => {
                return response;
            })
            .catch((error : any) => {
                this.factoryErrorHandling(error);
                throw new Error(error.response.data);
            });
    }

    async CreateCincConfiguredAdAccount(adAccountCreationRequest: AdAccountCreationRequest) : Promise<any> {
        return axios
            .post(`${this.apiUrl}api/Creator/AdAccount`, adAccountCreationRequest)
            .then((response : any) => {
                return response;
            })
            .catch((error : any) => {
                this.factoryErrorHandling(error);
                throw new Error(error.response.data);
            });
    }

    async CreateCincConfiguredCampaign(campaignCreationRequest: CampaignCreationRequest): Promise<string> {
        return axios
        .post(`${this.apiUrl}api/Creator/Campaign`, campaignCreationRequest)
        .then((response : any) => {
            return response;
        })
        .catch((error : any) => {
            this.factoryErrorHandling(error);
            throw new Error(error.response.data);
        });
    }

    async CreateCincConfiguredAdset(adsetCreationRequest: AdsetCreationRequest): Promise<string> {
        return axios
        .post(`${this.apiUrl}api/Creator/Adset`, adsetCreationRequest)
        .then((response : any) => {
            return response;
        })
        .catch((error : any) => {
            this.factoryErrorHandling(error);
            throw new Error(error.response.data);
        });
    }

    async CreateCincConfiguredAd(adCreationRequest: AdCreationRequest): Promise<string> {
        return axios
        .post(`${this.apiUrl}api/Creator/Ad`, adCreationRequest)
        .then((response : any) => {
            return response;
        })
        .catch((error : any) => {
            this.factoryErrorHandling(error);
            throw new Error(error.response.data);
        });
    }

    async CreateCincConfiguredForm(formCreationRequest: FormCreationRequest): Promise<string> {
        return axios
        .post(`${this.apiUrl}api/Creator/Form`, formCreationRequest)
        .then((response : any) => {
            return response;
        })
        .catch((error : any) => {
            this.factoryErrorHandling(error);
            throw new Error(error.response.data);
        });
    }

    async CreateAdAccountCreatorInfo(creatorInfoCreationRequest : CreatorInfoCreationRequest) : Promise<string> {
        return axios
        .post(`${this.apiUrl}api/Creator/CreatorInfo`, creatorInfoCreationRequest)
        .then((response : any) => {
            return response;
        })
        .catch((error : any) => {
            this.factoryErrorHandling(error);
            throw new Error(error.response.data);
        });
    }
   
    //Update
    async UpdateCampaign(campaign: Campaign) {
        return this.update(campaign, `api/Campaign/`);
    }
    async UpdateAdset(adset: Adset)  {
        return this.update(adset, `api/Adset/`);
    }
    async UpdateAd(ad: Ad) {
        return this.update(ad, `api/Ad/`);
    }

    //Get
    async GetAllAdAccounts() {
        return this.getMany(`api/Account/AdAccounts/`, this.mapAdAccounts)
    }
    async GetExistingCreatorInfos() {
        return this.getMany(`api/Creator/CreatorInfos/`, this.mapDirectResponse);
    }
    async GetCreatorInfo(adAccountId: string) {
        return this.getOne(`api/Creator/CreatorInfo/`, adAccountId, this.MapCreatorInfo);
    }
    async GetAdsetsByCampaignId(campaignId: string) {
        return this.getMany(`api/Campaign/${campaignId}/Adsets/`, this.mapAdsets);
    }
    async GetAdsetById(adsetId: string) {
        return this.getOne(`api/Adset/`, adsetId, this.mapAdset)
    }
    async GetAdByAdId(adId: string) {
        return this.getOne(`api/Ad/`, adId, this.mapAd);
    }
    async GetAdsByAdsetId(adsetId: string) {
        return this.getMany(`api/Adset/${adsetId}/Ads/`, this.mapAds);
    }
    async GetCampaignById(campaignId: string) {
        return this.getOne(`api/Campaign/`, campaignId, this.mapCampaign);
    }
    async GetCampaignsByAdAccountId(adAccountId: string) {
        return this.getMany(`api/Account/${adAccountId}/Campaigns/?status=NONE`, this.mapCampaigns);
    }
    async GetOption(optionName: string) {
        return this.getMany(`api/Option/${optionName}`, this.mapDirectResponse);
    }
    async GetFacebookPages() {
        return this.getMany(`api/Page/Pages/`, this.mapDirectResponse);
    }
    async GetFacebookForms(fbPageId: string) {
        return this.getMany(`api/Page/${fbPageId}/Forms`, this.mapDirectResponse);
    }
    async GetCompanyFacebookForms(fbPageId: string, company: Company) {
        return this.getMany(`api/Page/${fbPageId}/Forms/${company}`, this.mapDirectResponse);
    }
    async GetInterests() {
        return this.getMany(`api/Audience/Interests/`, this.mapDirectResponse);
    }
    async GetLocationType() {
        return this.getMany(`api/Audience/LocationType`, this.mapDirectResponse);
    }
    async GetAudiences(adAccountId: string) {
        if (!adAccountId.includes('act_')) {
            adAccountId = 'act_' + adAccountId;
        }
        return this.getMany(`api/Audience/All?adAccountId=${adAccountId}`, this.mapDirectResponse);
    }
    async GetCTAs() {
        return this.getMany(`api/Ad/CallToAction`, this.mapDirectResponse);
    }
    async GetAdTemplates() {
        return this.getMany(`api/Template/AdText`, this.mapDirectResponse);
    }
    async GetDynamicAdTemplates() {
        return this.getMany(`api/Template/DynamicAdText`, this.mapDirectResponse);
    }
    async GetAdTemplateById(templateId : string) {
        return this.getOne(`api/Template/AdText/`, templateId, this.mapDirectResponse);
    }
    async GetDynamicAdTemplateById(templateId : string) {
        return this.getOne(`api/Template/DynamicAdText/`, templateId, this.mapDirectResponse);
    }
    async GetFormTemplates() {
        return this.getMany('api/Template/Form', this.mapDirectResponse);
    }
    async GetFormTemplateById(templateId : string) {
        return this.getOne('api/Template/Form/', templateId, this.mapDirectResponse);
    }
    async GetButtonTypes() {
        return this.getMany(`api/Option/buttontype`, this.mapDirectResponse);
    }
    async GetLocationsBySearch(search: string) {
        return this.getMany(`api/Adset/Locations/${search}`, this.mapDirectResponse);
    }

    async GetProductSetsbyProductCatalogId(productCatalogRequest: ProductCatalogSet) {
        let params = new URLSearchParams(<Record<string, string>><unknown>productCatalogRequest).toString();
        return this.getMany(`api/ProductCatalog/ProductSets?${params}`, this.mapDirectResponse);
    }
    async GetAdSetLocales() {
        return this.getMany(`api/Option/Locales`, this.mapDirectResponse);
    }
    async QueueDeepCopyAdSet(adSetId : string) {
        return this.getOne('api/Adset/', `copy/${adSetId}`, this.mapDirectResponse);
    }
    async GetLeadForm(pageId : string, formId : string) {
        return this.getOne(`api/Form/`,`${pageId}/${formId}` ,this.mapLeadForm);
    }

    async GetFolderContent(prefix: string) {
        return this.getOne('api/Report', `?prefix=${prefix}`, this.mapDirectResponse);
    }

    async DownloadFile(path: string) {
        return axios
            .get(`${this.apiUrl}api/Report/Download?filePath=${path}`, { headers: {'Content-Type': 'text/plain'}})
            .then(response => {
                let data = response.data
                return this.mapDirectResponse(data);
            })
            .catch((error : any) => {
                this.factoryErrorHandling(error);
                throw new Error(error.response.data);
            });
        
    }
    
    //Delete
    async DeleteCampaign(campaignId: string) {
        return this.delete(`api/Campaign/`, campaignId);
    }
    async DeleteAdset(adsetId : string) {
        return this.delete(`api/Adset/`, adsetId);
    }
    async DeleteAd(adId: string) {
        return this.delete(`api/Ad/`, adId);
    }
    async PurgeOldWebhookLeads() {
        return this.deleteAll(`api/webhook/PurgeOld`);
    }

    //private verbs
    private getOne(edge : string, id: string, mappingFunction : Function) {
        return axios
            .get(`${this.apiUrl}${edge}${id}`)
            .then(response => {
                let data = response.data
                return mappingFunction(data);
            })
            .catch((error : any) => {
                this.factoryErrorHandling(error);
                throw new Error(error.response.data);
            });
    }

    private getMany(edge : string, mappingFunction : Function) {
        let result = axios
            .get(`${this.apiUrl}${edge}`)
            .then(response => {
                let data = response.data
                return mappingFunction(data);
            })
            .catch((error : any) => {
                this.factoryErrorHandling(error);
                throw new Error(error.response.data);
            });
        if(result) {
            return result;
        }
        return new Array();
    }

    async delete(edge : string, id: string): Promise<boolean> {
        return await axios
            .delete(`${this.apiUrl}${edge}${id}`)
            .then(() => {
                return true;
            })
            .catch((error : any) => {
                this.factoryErrorHandling(error);
                throw new Error(error.response.data);
            });
    }

    async deleteAll(edge: string) : Promise<number> {
        return await axios
            .delete(`${this.apiUrl}${edge}`)
            .then((response: any) => {
                return response.data;
            })
            .catch((error : any) => {
                this.factoryErrorHandling(error);
                throw new Error(error.response.data);
            });
    }

    async UpdateStatus(id: string, type: string, status: string) : Promise<boolean> {
        let statusRequest = new UpdateStatusRequest();
        statusRequest.id = id;
        statusRequest.type = type;
        statusRequest.status = status;
        return await axios
            .put(`${this.apiUrl}api/Status`, statusRequest)
            .then(() => {
                return true;
            }).catch((error : any) => {
                this.factoryErrorHandling(error);
                throw new Error(error.response.data);
            });
    }

    update<T extends IFacebookObject>(value: T, edge: string) : Promise<boolean> {
        return axios
            .put(`${this.apiUrl}${edge}${value.id}`, value)
            .then(() => {
                return true;
            })
            .catch((error : any) => {
                this.factoryErrorHandling(error);
                throw new Error(error.response.data);
            });
    }

    create<T extends IFacebookObject>(value: T, edge : string): Promise<string> {
        return axios
            .post(`${this.apiUrl}${edge}`, value)
            .then(response => { 
                return response.data.id;
            })
            .catch((error : any) => {
                this.factoryErrorHandling(error);
            });
    }
    createNonFBObject<T>(value : T, edge: string) : Promise<string> {
        return axios
            .post(`${this.apiUrl}${edge}`, value)
            .then(response => { 
                return response.data;
            })
            .catch((error : any) => {
                this.factoryErrorHandling(error);
            });
    }

    //Error Handling
    private factoryErrorHandling(error: any) {
        if(error.response.status === 401) { //unauthorized
            store.dispatch('logout');
            router.push({ name: 'login', params: { ErrorDisplayMessage: "Your session has expired or you are unauthorized. Please Login." }});
         }
        console.log(error);
    }

    //Mapping
    private mapAdAccounts(data : any) {
        return data.map((adAccount: any) => {
            let adAccountOb = new AdAccount();
            adAccountOb.name = adAccount.name;
            adAccountOb.status = adAccount.account_status;
            if (adAccount.id.includes("act_")) {
                adAccountOb.id = adAccount.id.replace("act_", "");
            } else {
                adAccountOb.id = adAccount.id;
            }
            return adAccountOb;
        });
    }
    public MapCreatorInfo(data : any) {
        let creatorInfo = new CreatorInfo();
        creatorInfo.AdAccountId = "act_"+data.adAccountId;
        switch (data.company) {
            case 'CINC':
                creatorInfo.Company = Company.CINC;
                break;
            case 'RealGeeks':
                creatorInfo.Company = Company.RealGeeks;
                break;
            default:
                creatorInfo.Company = Company.CINC;
        }
        creatorInfo.ClientFullName = data.clientFullName;   
        creatorInfo.DomainName = data.domainName;
        creatorInfo.States = data.states;
        creatorInfo.AdAccountName = data.adAccountName;
        creatorInfo.Campaigns = new Array<CreatorInfoCampaign>();
        if(data.campaigns !== null && data.campaigns !== undefined &&  data.campaigns.length > 0) {
            for(let campaignIndex = 0; campaignIndex < data.campaigns.length; campaignIndex++) {
                let campaign = new CreatorInfoCampaign();
                campaign.CampaignType = data.campaigns[campaignIndex].campaignType;
                campaign.CampaignName = data.campaigns[campaignIndex].campaignName;
                campaign.CampaignId = data.campaigns[campaignIndex].campaignId;
                campaign.Adsets = new Array<CreatorInfoAdset>();
                if(data.campaigns[campaignIndex].adsets !== null && data.campaigns[campaignIndex].adsets !== undefined &&  data.campaigns[campaignIndex].adsets.length > 0) {
                    for(let adsetIndex = 0; adsetIndex < data.campaigns[campaignIndex].adsets.length; adsetIndex++) {
                        let adset = new CreatorInfoAdset();
                        adset.AdsetId = data.campaigns[campaignIndex].adsets[adsetIndex].adsetId;
                        adset.AdsetName = data.campaigns[campaignIndex].adsets[adsetIndex].adsetName;
                        adset.PageId = data.campaigns[campaignIndex].adsets[adsetIndex].pageId;
                        adset.ProductSetId = data.campaigns[campaignIndex].adsets[adsetIndex].productSetId;
                        adset.Ads = new Array<CreatorInfoAd>();
                        if(data.campaigns[campaignIndex].adsets[adsetIndex].ads !== null && data.campaigns[campaignIndex].adsets[adsetIndex].ads !== undefined &&  data.campaigns[campaignIndex].adsets[adsetIndex].ads.length > 0) {
                            for(let adIndex = 0; adIndex < data.campaigns[campaignIndex].adsets[adsetIndex].ads.length; adIndex++) {
                                let ad = new CreatorInfoAd();
                                ad.AdId = data.campaigns[campaignIndex].adsets[adsetIndex].ads[adIndex].adId;
                                ad.AdName = data.campaigns[campaignIndex].adsets[adsetIndex].ads[adIndex].adName;
                                adset.Ads.push(ad);
                            }
                        }
                        campaign.Adsets.push(adset);
                    }
                }
                creatorInfo.Campaigns.push(campaign);
            }
        }
        return creatorInfo;
    }
    private mapCampaigns(data : any) {
        return data.campaigns.map((campaign: any) => {
            let campaignOb = new Campaign();
            campaignOb.name = campaign.name;
            campaignOb.status = campaign.status;
            campaignOb.createDate = new Date(campaign.createDate);
            campaignOb.adAccount = campaign.adAccount;
            if (!campaign.adAccountId.includes("act_")) {
                campaignOb.adAccountId = campaign.adAccountId.replace("", "act_");
            } else {
                campaignOb.adAccountId = campaign.adAccountId;
            }
            campaignOb.dailyBudget = campaign.dailyBudget;
            campaignOb.bidStrategy = campaign.bidStrategy;
            campaignOb.specialAdCategory = campaign.specialAdCategory;
            campaignOb.budgetOptimization = campaign.budgetOptimization;
            campaignOb.id = campaign.campaignId;
            return campaignOb;
        });
    }
    private mapCampaign(data : any) {
        let campaignOb = new Campaign();
        campaignOb.name = data.name;
        campaignOb.status = data.status;
        campaignOb.createDate = new Date(data.createDate);
        if (!campaignOb.adAccountId.includes("act_")) {
            campaignOb.adAccountId = data.adAccountId.replace("", "act_");
        } else {
            campaignOb.adAccountId = data.adAccountId;
        }
        campaignOb.dailyBudget = data.dailyBudget;
        campaignOb.bidStrategy = data.bidStrategy;
        campaignOb.specialAdCategory = data.specialAdCategory;
        campaignOb.budgetOptimization = data.budgetOptimization;
        campaignOb.campaignObjective = data.campaignObjective;
        campaignOb.id = data.campaignId;
        campaignOb.isDetailed = true;
        return campaignOb;
    }

    private mapAdsets(data: any) {
        return data.map((adset : any) => {
            let adsetOb = new Adset();
            adsetOb.name = adset.name;
            adsetOb.id = adset.id;
            adsetOb.status = adset.status;
            adsetOb.createDate = new Date(adset.created_time);
            return adsetOb;
        });
    }

    private mapAdset(data: any) {
        let adsetOb = new Adset();
        adsetOb.name = data.name;
        adsetOb.id = data.id;
        adsetOb.status = data.status;
        adsetOb.createDate = new Date(data.created_time);
        
        adsetOb.adAccountId = data.adAccountId;
        adsetOb.optimizationGoal = data.optimizationGoal;
        adsetOb.name = data.name;
        if(data.interests)
            adsetOb.interestIds = data.interests.map((interest: any) => { return interest.id; });
        if(data.regions) {
            adsetOb.regionIds = data.regions.map((region : any) => { return region.key; });
            adsetOb.regions = data.regions;
        }
        if(data.audiences)    
            adsetOb.audienceIds = data.audiences.map((audience: any) => { return audience.id; });
        if(data.cities)
            adsetOb.cities = data.cities;
        if(data.locationTypes)
            adsetOb.locationTypes = data.locationTypes;
        if(data.locales)
            adsetOb.locales = data.locales;
        adsetOb.fbPageId = data.fbPageId;        
        adsetOb.startTimeUtc = data.startTimeUtc;
        adsetOb.endTimeUtc = data.endTimeUtc;
        adsetOb.createDate = data.createTimeUtc;
        adsetOb.status = data.status;
        adsetOb.id = data.id;
        adsetOb.campaignId = data.campaignId;
        adsetOb.isDetailed = true;
        return adsetOb;
    }

    private mapAd(data: any) {
        let adOb = new Ad();
        adOb.name = data.name;
        adOb.body = data.body;
        adOb.linkHeadline = data.linkHeadline;
        adOb.linkDescription = data.linkDescription;
        adOb.callToAction = data.callToAction;
        adOb.imageUrl = data.imageUrl;
        adOb.formId = data.formId;
        adOb.status = data.status;
        adOb.effectiveStatus = data.effectiveStatus;
        return adOb;
    }

    private mapAds(data: any) {
        return data.map((ad : any) => {
            let adOb = new Ad();
            adOb.adAccountId = ad.adAccountId;
            adOb.adsetId = ad.adSetId;
            adOb.campaignId = ad.campaignId;
            adOb.name = ad.name;
            adOb.body = ad.body;
            adOb.linkHeadline = ad.linkHeadline;
            adOb.linkDescription = ad.linkDescription;
            adOb.callToAction = ad.callToAction;
            adOb.fbImageHash = ad.fbImageHash;
            adOb.imageUrl = ad.imageUrl;
            adOb.formId = ad.formId;
            adOb.id = ad.id;
            adOb.status = ad.status;
            adOb.effectiveStatus = ad.effectiveStatus;
            adOb.pageId = ad.pageId;
            return adOb;
        });
    }

    private mapLeadForm(data: any) : LeadFormModel {
        let leadForm = new LeadFormModel();
        leadForm.name = data.name;
        leadForm.id = data.id;
        leadForm.status = data.status;
        leadForm.pageId = data.pageId;
        leadForm.language = data.language
        leadForm.privacyPolicy = data.privacyPolicy;
        leadForm.questions = data.questions;

        let trackingParameters : TrackingParameter[] = new Array();
        for(var property in data.trackingParameters){
            let trackingParameter = new TrackingParameter();
            trackingParameter.name = property;
            trackingParameter.value = data.trackingParameters[property];
            trackingParameters.push(trackingParameter);
        }
        leadForm.trackingParameters = trackingParameters;
        leadForm.welcomeScreen = data.welcomeScreen;
        leadForm.thankYouScreen = data.thankYouScreen;

        return leadForm;
    }

    private mapDirectResponse(data: any) {
        return data;
    }
}