import { FacebookObject } from "./FacebookObject";
import { ValidationResponse, PropertyError } from "./ValidationResponse";
import { Language } from '../enums/Language';
import { FacebookFormButtonType } from '../enums/FacebookFormButtonType';
import { FacebookQuestionType } from '../enums/FacebookQuestionType';

class ThankYouScreen {
    headline: string = "";
    description: string = "";
    buttonType: FacebookFormButtonType = FacebookFormButtonType.NONE;
    buttonText: string = "";
    websiteLink: string = "";
}
class WelcomeScreen {
    headline: string = "";
    bullets: string[] = new Array<string>("");
}
class Questions {
    headline: string = "";
    basicQuestions = new Array<BasicQuestion>({type: FacebookQuestionType.NONE });
    optionQuestions = new Array<OptionQuestion>({type: FacebookQuestionType.CUSTOM, fieldText:"", options:new Array<string>("") });
}
class Question {
    type: FacebookQuestionType = FacebookQuestionType.NONE;
}
class BasicQuestion extends Question { }
class OptionQuestion extends Question {
    options: string[] = new Array<string>("");
    fieldText: string = "";
}

export class TrackingParameter {
    name: string = "";
    value: string = "";
}

class PrivacyPolicy {
    linkText: string = "";
    linkUrl: string = "";
}

export class LeadFormModel extends FacebookObject {
    constructor(duplicateLeadForm? : any) {
        super();
        if (duplicateLeadForm) {
            this.MapDuplicateLeadForm(duplicateLeadForm)
        }
    };

    pageId: string = "";

    language: Language = Language.English;

    privacyPolicy: PrivacyPolicy = new PrivacyPolicy;

    questions: Questions = new Questions;

    trackingParameters: TrackingParameter[] = new Array(); 

    welcomeScreen: WelcomeScreen = new WelcomeScreen;

    thankYouScreen: ThankYouScreen = new ThankYouScreen;


    public static readonly MAX_PrivacyPolicy_LinkText_LENGTH : number = 70;

    public static readonly WelcomeScreenHeadlineVal: string = "WelcomeScreenHeadlineVal";
    public static readonly WelcomeScreenBulletVal: string = "WelcomeScreenBulletVal";

    public static readonly QuestionsHeadlineVal: string = "QuestionsHeadlineVal";
    public static readonly QuestionTypeVal : string = "QuestionTypeVal";

    public static readonly PrivacyTextVal : string = "PrivacyTextVal";
    public static readonly PrivacyUrlVal : string = "PrivacyUrlVal";

    public static readonly ThankYouHeadlineVal: string = "ThankYouHeadlineVal";
    public static readonly ThankYouDescriptionVal: string = "ThankYouDescriptionVal";
    public static readonly ThankYouButtonTypeVal: string = "ThankYouButtonTypeVal";
    public static readonly ThankYouButtonTextVal: string = "ThankYouButtonTextVal";
    public static readonly ThankYouWebsiteLinkVal: string = "ThankYouWebsiteLinkVal";

    public static readonly ExtraUrlParametersVal: string = "ExtraUrlParametersVal";

    public static FacebookForms: any[];

    ValidateLeadForm(facebookForms : any[]) {
        LeadFormModel.FacebookForms = facebookForms;
        return this.Validate();
    }

    Validate(): ValidationResponse {
        let validationResponse = new ValidationResponse();
        
        this.checkPropertyError(this.leadFormNameValidation(), validationResponse);

        this.checkPropertyError(this.welcomeScreenHeadlineValidation(), validationResponse);
        this.checkPropertyError(this.welcomeScreenBulletValidation(), validationResponse);

        this.checkPropertyError(this.questionsHeadlineValidation(), validationResponse);
        this.checkPropertyError(this.basicQuestionTypeValidation(), validationResponse);

        this.checkPropertyError(this.privacyTextValidation(), validationResponse);
        this.checkPropertyError(this.privacyUrlValidation(), validationResponse);

        this.checkPropertyError(this.thankyouHeadlineValidation(), validationResponse);
        this.checkPropertyError(this.thankyouDescriptionValidation(), validationResponse);
        this.checkPropertyError(this.thankyouButtonTypeValidation(), validationResponse);
        this.checkPropertyError(this.thankyouButtonTextValidation(), validationResponse);
        this.checkPropertyError(this.thankyouWebsiteLinkValidation(), validationResponse);

        validationResponse.IsValid = validationResponse.ErrorMessages.length === 0;
        return validationResponse;
    }

    protected leadFormNameValidation() : PropertyError {
        if (this.name === "") {
            return new PropertyError(FacebookObject.NameVal, "Name is Required");
        }
        else if (LeadFormModel.FacebookForms.length > 0) {
            var isDuplicateName = false;
            var name = this.name;
            for (let i=0; i < LeadFormModel.FacebookForms.length; i++) {
                if (LeadFormModel.FacebookForms[i].name === name) {
                    isDuplicateName = true;
                    break;
                }
            }
            if (isDuplicateName){
                return new PropertyError(FacebookObject.NameVal, "Unique Form Name is Required");
            }
        }
        return new PropertyError("","");
    }

    protected welcomeScreenHeadlineValidation() : PropertyError {
        return (this.welcomeScreen.headline === "") ? new PropertyError(LeadFormModel.WelcomeScreenHeadlineVal, "Welcome Screen Headline is Required") : new PropertyError("","");
    }

    protected welcomeScreenBulletValidation() : PropertyError {
        return (this.welcomeScreen.bullets[0] === "") ? new PropertyError(LeadFormModel.WelcomeScreenBulletVal, "At least 1 bullet is Required") : new PropertyError("","");
    }

    protected questionsHeadlineValidation() : PropertyError {
        return (this.questions.headline === "") ? new PropertyError(LeadFormModel.QuestionsHeadlineVal, "Questions Headline is Required") : new PropertyError("","");
    }

    protected basicQuestionTypeValidation() : PropertyError {
        return (this.questions.basicQuestions[0].type === FacebookQuestionType.NONE) ? new PropertyError(LeadFormModel.QuestionTypeVal, "1 Basic Question Required") : new PropertyError("","");
    }

    protected privacyTextValidation() : PropertyError {
        if (this.privacyPolicy.linkText === "") {
            return new PropertyError(LeadFormModel.PrivacyTextVal, "Privacy Text is Required");
        } 
        else if (this.privacyPolicy.linkText !== "" && this.privacyPolicy.linkText.length > LeadFormModel.MAX_PrivacyPolicy_LinkText_LENGTH) {
            return new PropertyError(LeadFormModel.PrivacyTextVal, "Privacy Text length can be no more than 70 characters.");
        }
        return new PropertyError("","");
    }
    protected privacyUrlValidation() : PropertyError {
        if (this.privacyPolicy.linkUrl === "") {
            return new PropertyError(LeadFormModel.PrivacyUrlVal, "Privacy Url is Required");
        } 
        else if (this.privacyPolicy.linkUrl !== "" && !this.privacyPolicy.linkUrl.includes('.')) {
            return new PropertyError(LeadFormModel.PrivacyUrlVal, "Privacy is required to be a Url");
        }
        return new PropertyError("","");
    }

    protected thankyouHeadlineValidation() : PropertyError {
        return (this.thankYouScreen.headline === "") ? new PropertyError(LeadFormModel.ThankYouHeadlineVal, "Headline is Required") : new PropertyError("","");
    }
    protected thankyouDescriptionValidation() : PropertyError {
        return (this.thankYouScreen.description === "") ? new PropertyError(LeadFormModel.ThankYouDescriptionVal, "Description is Required") : new PropertyError("","");
    }
    protected thankyouButtonTypeValidation() : PropertyError {
        return (this.thankYouScreen.buttonType === FacebookFormButtonType.NONE) ? new PropertyError(LeadFormModel.ThankYouButtonTypeVal, "Button Type is Required") : new PropertyError("","");
    }
    protected thankyouButtonTextValidation() : PropertyError {
        return (this.thankYouScreen.buttonText === "") ? new PropertyError(LeadFormModel.ThankYouButtonTextVal, "Button Text is Required") : new PropertyError("","");
    }
    protected thankyouWebsiteLinkValidation() : PropertyError {
        if (this.thankYouScreen.websiteLink === "") {
            return new PropertyError(LeadFormModel.ThankYouWebsiteLinkVal, "Website Link is Required");
        } 
        else if (this.thankYouScreen.websiteLink !== "" && !this.thankYouScreen.websiteLink.includes('.')) {
            return new PropertyError(LeadFormModel.ThankYouWebsiteLinkVal, "Website Link is required to be a Url");
        }
        return new PropertyError("","");
    }

    private MapDuplicateLeadForm(duplicateLeadForm : LeadFormModel) {
        this.name = duplicateLeadForm.name + ' -copy';
        this.welcomeScreen = duplicateLeadForm.welcomeScreen;
        this.questions = duplicateLeadForm.questions;
        this.privacyPolicy = duplicateLeadForm.privacyPolicy;
        this.thankYouScreen = duplicateLeadForm.thankYouScreen;
        this.trackingParameters = duplicateLeadForm.trackingParameters;
        this.thankYouScreen.websiteLink = duplicateLeadForm.thankYouScreen.websiteLink;
    }
}