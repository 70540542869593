import { FolderContent } from "../../models/FolderContent";
import { ReportCreationRequest } from "../../models/ReportCreationRequest";

const reportStore = ({
    namespaced: true,
    actions: {
        async getFolderContent({commit, rootState, rootGetters} : {commit:any, state:any, rootState: any, rootGetters :any}, prefix?: string) {
            var folderContent = new FolderContent();
            await rootGetters.facebookMarketingFactory.GetFolderContent(prefix)
            .then((response : any) => {
                folderContent = response;
            })
            return folderContent;
        },
        async downloadFile({commit, rootState, rootGetters} : {commit:any, state:any, rootState: any, rootGetters :any}, path: string) {
            var response = await rootGetters.facebookMarketingFactory.DownloadFile(path)
            return response;
        },
        async sendReportRequest({commit, rootState, rootGetters} : {commit:any, state:any, rootState: any, rootGetters :any}, reportRequest: ReportCreationRequest) {
            return new Promise((resolve, reject) => {
                rootGetters.facebookMarketingFactory.SendReportRequest(reportRequest)
                    .then((response : any) => {
                        resolve(response);
                    }, (error : any) => {
                        reject(error);
                    })
            });
        }
    },
})

export default reportStore