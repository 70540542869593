<template>
    <div>
        <div class="global-container ml-3">
            <nav v-if="authenticated" class="navbar navbar-expand-sm navbar-dark bg-primary">
                <div class="contain-fluid">
                    <a class="navbar-brand ml-3" href="#" style="margin-left:20px">
                        Facebook Automation
                    </a>
                </div>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <router-link class="nav-link" :class="currentRoutePath === adAccountCreatorRoute ? 'active' : ''" :disabled="currentRoutePath === adAccountCreatorRoute" :to=adAccountCreatorRoute>Ad Account Creator</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :class="currentRoutePath === creatorInfoLoaderRoute ? 'active' : ''" :disabled="currentRoutePath === creatorInfoLoaderRoute" :to=creatorInfoLoaderRoute>Creator Info Loader</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :class="currentRoutePath === permissionsGranterRoute ? 'active' : ''" :disabled="currentRoutePath === permissionsGranterRoute" :to=permissionsGranterRoute>Permissions Granter</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :class="currentRoutePath === facebookReportsRoute ? 'active' : ''" :disabled="currentRoutePath === facebookReportsRoute" :to=facebookReportsRoute>Reports</router-link>
                        </li>
                    </ul>
                </div>  
                <div class="d-flex text-light">
                    <div class="nav-link pl-3"> {{ userName }} </div>
                    <a @click=logout() class="nav-link" title="Log Out" style="cursor: pointer;">
                        <font-awesome-icon icon="sign-out-alt" style="margin: 6px; margin-right:20px;"></font-awesome-icon>
                    </a>
                </div>   
            </nav>
            <div class="router-view-container">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    export default {
        name: 'App',
        data: function () {
            return {
                authenticated: false,
                name: "",
                claims: null,
                adAccountCreatorRoute: "/facebook/creator/adaccount",
                templateManagerRoute: "/facebook/template/manager",
                permissionsGranterRoute: "/facebook/permissionsgranter",
                creatorInfoLoaderRoute: "/facebook/creator/infoloader",
                facebookReportsRoute: "/facebook/reports/existing",
            }
        },
        async created() {
            await this.isAuthenticated();
        },
        watch: {
            '$route': 'isAuthenticated',
        },
        methods: {
            async isAuthenticated() {
                this.authenticated = await this.$store.dispatch('isAuthenticated')
            },
            async logout() {
                this.$store.dispatch('logout');
                this.$router.push({ path: '/login'});
            }
        },
        computed: {
            currentRoutePath() {
                return this.$route.path;
            },
            userName() {
                return this.$store.getters["userName"];
            },
        }
    }
</script>

<style>
    @import './static/bootstrap.min.5.3.3.css';
</style>